import grid_vars from 'simpl-grid/variables.scss'
import { randomId } from './util/domUtils'

export const noOp = Function.prototype

export const reduxStorageKey = 'noted.ink:'
export const ONE_SECOND = 1000
export const ONE_MINUTE = ONE_SECOND * 60
export const ONE_HOUR = ONE_MINUTE * 60
export const ONE_DAY = ONE_HOUR * 24
export const ONE_YEAR = ONE_DAY * 365

export const THEMES = {
  'light': '#EEEEEE',
  'dark': '#000000',
  'warm': '#e8e6dc',
  'cool': '#dce4e8',
}

export const HOME_VIEWS = {
  'grid': 'grid',
  'list': 'list'
}

export const HOME_VIEW_NAMES = Object.keys(HOME_VIEWS)
export const THEME_NAMES = Object.keys(THEMES)
export const DEFAULT_THEME = THEME_NAMES[0]

export const CONTENT_COMPONENTS = {
  TEXT: 'TEXT',
  CHECKLIST: 'CHECKLIST',
  IMAGE: 'IMAGE',
  DRAWING: 'DRAWING',
}

export const DEFAULT_MASON = {
  small: {
    query: `(max-width: ${grid_vars.medium})`,
    columns: 2
  },
  medium: {
    query: `(min-width: calc(${grid_vars.medium} + 1px)) and (max-width: calc(${grid_vars.large} - 1px) )`,
    columns: 3
  },
  large: {
    query: `(min-width: ${grid_vars.large})`,
    columns: 4
  },
}

export const IMAGE_FILTERS = {
  'brightness': {min: -100, max: 100, default: 0, cssKey: 'brightness', groom: v => `${(+v + 100) + (Math.max(0, +v) * 1.25)}%`},
  'contrast': {min: -100, max: 100, default: 0, cssKey: 'contrast', groom: v => `${(+v + 100) + (Math.max(0, +v) * 1.25)}%`},
  'saturation': {min: -100, max: 100, default: 0, cssKey: 'saturate', groom: v => `${(+v + 100) + (Math.max(0, +v) * 1.25)}%`},
  'hue rotate': {min: -180, max: 180, default: 0, cssKey: 'hue-rotate', groom: v => `${v}DEG`},
}

export const newCheckListItem = () => ({
  id: randomId(),
  text: '',
  complete: false,
})

export const newTextComponent = () => ({
  component: CONTENT_COMPONENTS.TEXT,
  content: '',
})

export const newDrawingComponent = () => ({
  component: CONTENT_COMPONENTS.DRAWING,
  content: {},
})

export const newImageComponent = () => ({
  component: CONTENT_COMPONENTS.IMAGE,
  content: {
   url: false,
   filters: { }
  },
})

export const newChecklistComponent = () => ({
  text: '',
  component: CONTENT_COMPONENTS.CHECKLIST,
  content: [newCheckListItem()],
})

export const generateNewNote = () => ({
  title: '',
  content: [
    newTextComponent(),
  ]
})

export const generateNewGroup = () => ({
  name: '',
  color: '',
  icon: '',
  notes: []
})

export const IMGUR_CLIENT_ID = '22a2940fa4a70e7'

export const HSL_WHITE = {
  hue: 206,
  saturation: 0,
  lumen: 100,
  opacity: 1
}

export const HSL_BLACK = {
  hue: 206,
  saturation: 0,
  lumen: 0,
  opacity: 1
}
