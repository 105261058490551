export const getInitialState = (storageKey) => {
  // iterate through local storage, and extract items whose key start with the storage key
  // check if they've expired.  if they haven't, add them to initial state.  If they have, delete them.
  // When we've iterated through all of them, return initial state.

  let initialState = {}

  Object.keys(window.localStorage).forEach(key => {
    const isPersistedItem = key.indexOf(storageKey) === 0
    const localSnapShot = isPersistedItem && JSON.parse(window.localStorage.getItem(key))
    const isAlive = localSnapShot && localSnapShot.timeOfDeath > Date.now()
    const isDead = localSnapShot && localSnapShot.timeOfDeath < Date.now()
    if (isAlive) {
      initialState[key.split(storageKey)[1]] = JSON.parse(localSnapShot.json || '')
    }
    if (isDead) {
      window.localStorage.removeItem(key)
    }
  })
  return initialState
}

export const syncLocalStorageWithRedux = (storageKey, whiteList, state) => {
  // iterate through our white list.
  // if the value hasn't been saved before, or the value in redux has
  // changed since the last time this was saved to local storage,
  // extend the life span and save the new value.

  whiteList.forEach(item => {
    const thisItemKey = storageKey + item.key

    if (state[item.key]) {
      const storeJSON = JSON.stringify(state[item.key])
      const storeSnapShot = JSON.stringify({ timeOfDeath: Date.now() + item.lifeSpan, json: storeJSON })

      const localSnapShot = window.localStorage.getItem(thisItemKey)
      const localSnapShotValue = localSnapShot && JSON.parse(localSnapShot)

      if (!localSnapShotValue || storeJSON !== localSnapShotValue.json) {
        window.localStorage.setItem(thisItemKey, storeSnapShot)
      }
    }
  })
}