import React from 'react'
import {FunctionComponent, ReactElement} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {promptUpdate} from '../../store/UIState/UIStateActions'
import {reduxState} from '../../types'

import './UpdatePrompt.scss'

const UpdatePrompt:FunctionComponent = function () : ReactElement {
  const serviceWorker = useSelector((state: reduxState) => state?.uiState?.worker)
  const dispatch = useDispatch()

  const update = () => {
    if (serviceWorker ) {

      serviceWorker.postMessage({ type: 'SKIP_WAITING' });
      serviceWorker.addEventListener('statechange', (e) => {
        // @ts-ignore
        if (e?.target?.state === 'activated') {
          window.location.reload();
        }
      })
    }
  }

  const dismiss = () => {
    dispatch(promptUpdate(false))
  }

  return <div className='update-prompt'>
    <div className='notice pull-left'>New Version</div>

    <div className='pull-right'>
      <button className='red' onClick={dismiss}>
        <i className='fas fa-times' />
        Dismiss
      </button>
      <button className='green' onClick={update}>
        <i className='fas fa-sync-alt' />
        Update
      </button>
    </div>
  </div>
}

export default UpdatePrompt
