import React, {FunctionComponent, ReactElement} from 'react'
import { ModalProps } from './index'

const Alert:FunctionComponent<ModalProps> = function (props) : ReactElement {
  const { title, message, closeModal = () => {} } = props

  return <React.Fragment>
    <button className='close-modal icon red' onClick={() => closeModal()}><i className='fa fa-times'/></button>
    <div className='medium-padding large-padding-bottom'>
      {title && <h2 className='modal-title large-margin-bottom'>{title}</h2>}
      {message && <p>{message}</p>}
    </div>
    <div className='small-padding'>
      <button className='on-foreground' onClick={() => closeModal()}>ok</button>
    </div>
  </React.Fragment>
}

export default Alert
