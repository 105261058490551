export const TOGGLE_NAV = 'TOGGLE_NAV'
export const SET_NAV = 'SET_NAV'
export const PROMPT_UPDATE = 'PROMPT_UPDATE'

export const toggleNavMenu = () => {
  return {
    type: TOGGLE_NAV
  }
}


export const setNavMenu = (state) => {
  return {
    type: SET_NAV,
    state
  }
}

export const promptUpdate = (state, worker?: ServiceWorker) => {
  return {
    type: PROMPT_UPDATE,
    state,
    worker,
  }
}