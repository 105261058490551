import { createStore, compose, combineReducers, applyMiddleware  } from 'redux'
import auth from './Auth/AuthReducer'
import notes from './Notes/NotesReducer'
import uiState from './UIState/UIStateReducer'
import preferences from './Preferences/PreferencesReducer'

import { authMiddleware } from './Auth/AuthMiddleware'
import { notesMiddleware } from './Notes/NotesMiddleware'
import { preferencesMiddleware } from './Preferences/PreferencesMiddleware'
import { getInitialState, syncLocalStorageWithRedux } from '../util/util-localStorage'
import { ONE_YEAR, reduxStorageKey } from '../constants'

const rootReducer = combineReducers({ auth, notes, uiState, preferences })
const middleware = [
  authMiddleware,
  notesMiddleware,
  preferencesMiddleware
]

const enhancers = [ applyMiddleware(...middleware) ]

if (window.__REDUX_DEVTOOLS_EXTENSION__) {
  enhancers.push(window.__REDUX_DEVTOOLS_EXTENSION__())
}
const reduxStorageWhiteList = [
  { key: 'preferences', lifeSpan: ONE_YEAR },
]

const initialState = getInitialState(reduxStorageKey)

const store = createStore(rootReducer, initialState, compose(...enhancers))

store.subscribe(() => syncLocalStorageWithRedux(reduxStorageKey, reduxStorageWhiteList, store.getState()))

export default store
