export const ICONS = [
  'fas fa-home',
  'fas fa-flask',
  'fas fa-hammer',
  'fas fa-chart-bar',
  'fas fa-camera',
  'fas fa-birthday-cake',
  'fas fa-burn',
  'fas fa-car',
  'fas fa-biking',
  'fas fa-motorcycle',
  'fas fa-cart-plus',
  'fas fa-cloud-sun',
  'fas fa-gift',
  'fas fa-envelope',
  'fas fa-globe-americas',
  'fas fa-key',
  'fas fa-map-marker-alt',
  'fas fa-microphone',
  'fas fa-tree',
  'fas fa-plane',
  'fas fa-carrot',
  'fas fa-bed',
  'fas fa-bell',
]

export const MORE_ICONS = [
  'fas fa-cog',
  'fas fa-gem',
  'fas fa-feather-alt',
  'fas fa-dog',
  'fas fa-crow',
  'fas fa-cat',
  'fas fa-fish',
  'fas fa-horse',
  'fas fa-dumpster-fire',
  'fab fa-accessible-icon',
  'fas fa-ghost',
  'fas fa-dice',
  'fas fa-cocktail',
  'fas fa-snowflake',
  'fab fa-fort-awesome',
  'fas fa-dungeon',
  'fas fa-hat-wizard',
  'fas fa-football-ball',
  'fas fa-futbol',
  'fas fa-baseball-ball',
  'fas fa-basketball-ball',
]