import React, {CSSProperties, FunctionComponent, ReactElement} from 'react'

import './SlideContainer.scss'

type slideContainerProps = {
  controlWidth?: string,
  controls?: ReactElement[],
  className?: string,
  style?: CSSProperties,
}

const SlideContainer:FunctionComponent<slideContainerProps> = function (props) : ReactElement {
  const {
    controlWidth = '0',
    controls = [],
    className = '',
    style = {},
  } = props

  const containerStyle = {
    ...style,
    '--control-width': controlWidth,
  }

  const hasControls = Boolean(controls.length)

  return <section className={`slide-container ${className}`} style={containerStyle} tabIndex={0}>
    <div className={`slide-content-wrapper ${hasControls ? 'controls' : ''}`} >
      <div className={'slide-content'} >
        {props.children}
      </div>
    </div>

    <div className='slide-content-controls'>
      {controls}
    </div>
  </section>
}

export default SlideContainer