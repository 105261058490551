import {
  LOGIN_USER,
  LOGOUT_USER,
} from './AuthConstants'

export const loginUser = (user) => {
  return {
    type: LOGIN_USER,
    user
  }
}

export const logoutUser = () => {
  return { type: LOGOUT_USER }
}