export const f = true

export const FAUX_GROUPS = [
  'Other',
  'Shared',
]

export const NO_GROUP = FAUX_GROUPS[0]
export const SHARED = FAUX_GROUPS[1]

export const createFauxGroup = (name, notes, color?, icon?, order?) => ({
  name, notes, color, icon, order
})