import React, {FunctionComponent, ReactElement, useState} from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { DEFAULT_THEME, HOME_VIEWS, THEMES } from '../../constants'
import { PATHS } from '../pathConstants'

import Header from '../../components/Header'
import Selection from './components/Selection'
import {useDocumentTitle, useThemeColor} from '../../util/domUtils'
import { reduxState, userShape } from '../../types'
import { useModal } from 'react-imperial-modal'
import {Generic} from '../../components/Modals'
import GridView from './components/GridView'
import ListView from './components/ListView'
import { useLocalStorage } from '@rehooks/local-storage'
import { createNewNote } from '../../util/dbUtils/noteUtils'
import {blendColors} from '../../util/color'

import './Home.scss'

const Home: FunctionComponent = function () : ReactElement {
  const [ selectedNotes, setSelectedNotes ] = useState<string []>([])
  const activeTheme = useSelector((state: reduxState) => state?.preferences?.theme)
  const noteSettings = useSelector((state: reduxState) => state?.notes?.settings ?? {})
  const allNotesMap = useSelector((state: reduxState)  => state?.notes?.notes ?? {})
  const allLengthsMap = useSelector((state: reduxState)  => state?.notes?.noteLengths ?? {})
  const noteGroups = useSelector((state: reduxState)  => state?.notes?.groups ?? {})
  const user = useSelector((state: reduxState)  => state?.auth?.user as userShape)
  const [ activeView ] = useLocalStorage('activeView', HOME_VIEWS.grid)
  const [ activeGroup, setActiveGroup ] = useLocalStorage('activeGroup', '')
  const [ openModal, closeModal ] = useModal()
  const history = useHistory()
  const thisActiveGroup = noteGroups?.[activeGroup]
  const groupNames = Object.keys(noteGroups)
  const displayNotes = {}

  const themeColor = THEMES[activeTheme] || THEMES[DEFAULT_THEME]
  const groupColor = activeGroup && noteGroups[activeGroup]?.color
  const groupThemeColor = groupColor && blendColors(groupColor, '#000000', .80)

  useDocumentTitle(`Noted ${ activeGroup ? ` | ${noteGroups[activeGroup]?.name || ''}` : ''}`)
  useThemeColor(groupThemeColor || themeColor)

  if (activeGroup) {
    Object.keys(noteGroups[activeGroup] || {}).forEach(noteId => {
      displayNotes[noteId] = allNotesMap?.[noteId]
    })
  } else {
    Object.keys(allNotesMap).forEach(noteId => {
      displayNotes[noteId] = allNotesMap?.[noteId]
    })
  }

  const handleAddNote = (group) : void => {
    const thisNewNote = createNewNote(user, group)
    history.push(PATHS.edit.replace(':id', thisNewNote.key as string))
  }

  const toggleNote = (noteId : string) : void => {
    const isSelected = selectedNotes.includes(noteId)

    if (isSelected) {
      const newSelectedNotes = [...selectedNotes]
      newSelectedNotes.splice(selectedNotes.indexOf(noteId), 1)
      setSelectedNotes(newSelectedNotes)
    } else {
      setSelectedNotes([ ...selectedNotes, noteId ])
    }
  }

  const handleSelectGroup = (group) : void => {
    setActiveGroup(group)
  }

  const hasNotes = Object.keys(displayNotes).length > 0
  const headerAdditional = selectedNotes.length > 0 && <Selection
    user={user}
    selectedNotes={selectedNotes}
    setSelectedNotes={setSelectedNotes}
    noteGroups={noteGroups} />

  const handleSelectCategory = () => {
    const CategoryModal = <Generic message={(
      <React.Fragment>
        <h1>Select a category</h1>
        <div className='medium-padding-top medium-padding-bottom'>
          {groupNames.map(group => (
            <button
              key={group}
              className='blue on-foreground'
              onClick={() => {
                handleSelectGroup(group);
                closeModal(CategoryModal);
              }}>
              <i className={noteGroups[group].icon || 'fas fa-tag'} />
              {noteGroups[group].name}
            </button>
          ))}
          <button
            className='blue on-foreground'
            onClick={() => {
              handleSelectGroup('');
              closeModal(CategoryModal);
            }}><i className='fas fa-tag' />All Notes</button>
        </div>
      </React.Fragment>
    )} closeModal={() => closeModal(CategoryModal)}/>

    openModal(CategoryModal)
  }

  return <React.Fragment>
    <Header secondRow={headerAdditional} background={thisActiveGroup?.color} >
      <h1 className='small-padding no-margin'>
        <button className='all-notes' onClick={() => activeGroup && handleSelectGroup('')}>Noted</button>
        {groupNames.length > 0 && <React.Fragment>
          <span className='slash'>{`/`}</span>
          <button className='active-group' onClick={handleSelectCategory} >
            {thisActiveGroup ? thisActiveGroup.name : 'All Notes' }
            &nbsp;&nbsp;
            <i className='fas fa-caret-down'/>
          </button>
        </React.Fragment>}
      </h1>
    </Header>

    <div className='home-container'>

      {activeView === HOME_VIEWS.grid && <GridView
        addNote={handleAddNote}
        hasNotes={hasNotes}
        activeGroup={activeGroup}
        selectedNotes={selectedNotes}
        noteSettings={noteSettings}
        noteGroups={noteGroups}
        toggleNote={toggleNote}
        allNotesMap={allNotesMap}
        allLengthsMap={allLengthsMap}
      />}

      {activeView === HOME_VIEWS.list && <ListView
        addNote={handleAddNote}
        hasNotes={hasNotes}
        selectedNotes={selectedNotes}
        toggleNote={toggleNote}
      />}

      <button className='button fab' onClick={() => handleAddNote(activeGroup)}><i className='fas fa-plus' /></button>
    </div>
  </React.Fragment>
}

export default Home
