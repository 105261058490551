import React, {FunctionComponent, ReactElement, useState} from 'react'
import { useModal } from 'react-imperial-modal'
import { Alert, Confirm, EditCategory } from '../../../../components/Modals'
import { noteGroup, userShape } from '../../../../types'
import { removeSharedNote } from '../../../../util/dbUtils/noteUtils'
import { addNotesToGroup, createNewGroup } from '../../../../util/dbUtils/groupsUtil'

import './Selection.scss'

const SELECTION_STATES = {
  categories: 'categories'
}

type SelectionProps = {
  user: userShape
  selectedNotes: string[]
  setSelectedNotes: Function
  noteGroups: {
    [key: string]: noteGroup
  }
}

const Selection: FunctionComponent<SelectionProps> = function (props): ReactElement {
  const [ selectState, setSelectState ] = useState('')
  const [ openModal, closeModal ] = useModal()
  const { user, selectedNotes, setSelectedNotes, noteGroups } = props
  const hasCategories = Boolean(Object.keys(noteGroups).length)

  const addToNewGroup = (newCategory) => {
    if (newCategory) {
      const newGroup = createNewGroup(user, newCategory)
      addToGroup(newGroup.key, newCategory.name)
    }
  }

  const addToGroup = (groupId, groupName) => {
    addNotesToGroup(user, selectedNotes, groupId)

    const successMessage = <Alert
      title='Success'
      message={`${selectedNotes.length} notes were added to '${groupName}'`}
      closeModal={() => { closeModal(successMessage) }} />

    openModal(successMessage).then(() => setSelectedNotes([]))
  }

  const removeSelectedNotes = () => {

    const deletePromises = selectedNotes.map(noteId => {
      return removeSharedNote(user, noteId, Object.keys(noteGroups))
    })

    Promise.all(deletePromises).then(() => {
      const successMessage = <Alert
        title='Success'
        message={`Deleted ${selectedNotes.length} notes`}
        closeModal={() => closeModal(successMessage)} />

      openModal(successMessage).then(() => setSelectedNotes([]))
    })
  }

  const handleDelete = () => {
    const ConfirmModal = <Confirm
      title='Are you quite certain?'
      message={`delete ${selectedNotes.length} note(s)? this cannot be undone`}
      closeModal={result => closeModal(ConfirmModal, result) } />

    openModal(ConfirmModal).then(result => result && removeSelectedNotes())
  }

  const handleAddCategory = () => {
    const EditModal = <EditCategory
      title='New Category'
      closeModal={result => closeModal(EditModal, result)} />

    openModal(EditModal).then(newGroup => newGroup && addToNewGroup(newGroup))
  }

  const handleAdd = () => {
    if (hasCategories) {
      setSelectState(SELECTION_STATES.categories)
    } else {
      handleAddCategory()
    }
  }

  const Default = <React.Fragment>
    <div className='row no-gutter default-state'>
      <div className='small-5 no-cell-padding number-selected'>
        <h3 className='no-margin medium-padding-left'>{selectedNotes.length} selected</h3>
      </div>
      <div className='small-7 no-cell-padding selection-ctas'>
        <button className='small green' onClick={handleAdd}><i className='fas fa-tag' /> add</button>
        <button className='small red' onClick={handleDelete}><i className='fas fa-trash-alt' /> delete</button>
      </div>
    </div>
  </React.Fragment>

  const Categories = <React.Fragment>
    <span className='heading-4 no-margin medium-padding-left'>
      <i className='fas fa-tag medium-padding-right hide-for-small' />
    </span>
    {Object.keys(noteGroups).map((groupId, i) => <button
      key={`cat-${groupId}`}
      className='small'
      onClick={() => addToGroup(groupId, noteGroups[groupId].name)}>{noteGroups[groupId].name || '-untitled-'}</button>)}
    <button className='small green' onClick={handleAddCategory}><i className='fas fa-plus' /> new</button>
  </React.Fragment>

  const defaultState = !selectState
  const categoryState = selectState === SELECTION_STATES.categories

  return <React.Fragment>
    <div className='add-to small-12'>
      <div>
        {defaultState && <button
          className='red icon small'
          onClick={() => setSelectedNotes([])}>
          <i className='fas fa-times' />
        </button>}

        {categoryState && <button
          className='icon small'
          onClick={() => setSelectState('')}>
          <i className='fas fa-arrow-left' />
        </button>}
      </div>
      <div className='select-state'>
        {defaultState && Default}
        {categoryState && Categories}
      </div>
    </div>


  </React.Fragment>
}

export default Selection
