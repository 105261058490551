import React, {FunctionComponent, ReactElement} from 'react'
import {noteShape, noteSettings, noteGroup} from '../../types'
import { CONTENT_COMPONENTS } from '../../constants'
import { SETTING_KEYS } from '../../mock/defaultData'
import { getFilterCSS } from '../../util'
import SVG from '../SVG'

import './NoteCard.scss'

type NoteCardProps = {
  group?: noteGroup,
  note: noteShape,
  settings?: noteSettings,
  sizePriority?: number,
  shared?: boolean
}

const NoteCard:FunctionComponent<NoteCardProps> = function (props) : ReactElement | null {
  const { note, settings, sizePriority = 0, shared } = props
  const isBulletList = settings?.[SETTING_KEYS.bulletList]
  const noteColor = settings?.[SETTING_KEYS.color]
  const cardStyle = {
    '--size-priority': sizePriority,
    '--note-color': noteColor ?? 'var(--foreground)',
  } as React.CSSProperties

  const firstGraphic = note?.content && Object.values(note.content).find(item => {
    const isDrawing = item.component === CONTENT_COMPONENTS.DRAWING
    const isImage = item.component === CONTENT_COMPONENTS.IMAGE
    return isDrawing || isImage
  })

  return note ? <article className={`note-card ${shared ? 'shared' : ''}`} style={cardStyle}>
    {note.title && <h2 className='note-title heading-4'>
      {note.title}
    </h2>}
    <div className='note-card-content'>
      {note.content && Object.values(note.content).map((item, i) => {
        const noteContentAsObject = {...note.content}
        const contentKey = Object.keys(noteContentAsObject).find(key => noteContentAsObject[key] === item)

        let contentItem
        let vectorPaths = item?.content?.vectorPaths
        let paddingBottom = ((item.content?.vectorHeight / item.content?.vectorWidth) * 100) + '%'
        let svgStyle = { '--padding-bottom': paddingBottom } as React.CSSProperties
        let imageSvg = firstGraphic && <div className='svg-container' style={ svgStyle }>
          <SVG drawing={item.component === CONTENT_COMPONENTS.DRAWING} {...item?.content} />
        </div>

        switch (item.component) {
          case CONTENT_COMPONENTS.TEXT:
            contentItem = <pre>{item.content}</pre>
            break

          case CONTENT_COMPONENTS.CHECKLIST:
            contentItem = <ul className={isBulletList ? 'bullets' : ''}>
              {item.text && <li className='list-title'><strong>{item.text}</strong></li>}
              {item.content.filter(item => !item.complete).map((cli, ii) => <li key={`cli${ii}`} className={`item ${cli.complete ? 'complete' : ''}`}>
                <span>{cli.text}</span>
              </li>)}
              {item.content.filter(item => item.complete).map((cli, ii) => <li key={`cli${ii}`} className={`item ${cli.complete ? 'complete' : ''}`}>
                <span>{cli.text}</span>
              </li>)}
            </ul>
            break

          case CONTENT_COMPONENTS.DRAWING:
            contentItem = item === firstGraphic && <div className='vector-container'>
              {vectorPaths && imageSvg}
            </div>
            break

          case CONTENT_COMPONENTS.IMAGE:
            let imageStyle = {
              filter: item.content?.filter ? getFilterCSS(item.content?.filter) : ''
            }
            contentItem = item === firstGraphic && <div className='image-vector-container'>
              {item.content.vectorPaths && imageSvg}
              <img src={item.content.url} alt={note.title} style={imageStyle} />
            </div>
            break

          default:
        }

        return contentItem ? <div key={`item-${contentKey}`} className='note-content-item'>{contentItem}</div> : null
      })}
    </div>
    <footer className='note-card-footer'>
      {shared && <i className='fas fa-user-friends' />}
    </footer>
  </article> : null
}

export default NoteCard
