import React from 'react'
import { useSelector } from 'react-redux'
import { Route, Redirect } from 'react-router-dom'
import { PATHS } from '../routes/pathConstants'
import queryParams, { isURLAbsolute } from './queryParams'

const redirectParamName = 'redirect'

export const AuthOnlyRoute = function({ children, ...rest }) {
  const isAuthenticated = useSelector(state => state?.auth?.user)
  const isAuthenticating = typeof isAuthenticated === 'undefined'
  const redirectParam = `?${redirectParamName}=${window.location.pathname + window.location.search + window.location.hash}`
  
  if (isAuthenticating) { return null }
  return isAuthenticated ? <Route {...rest} >{children}</Route> : <Redirect to={`${PATHS.login}${redirectParam}`} />
}

export const AnonOnlyRoute = function ({ children, ...rest }) {
  const isAuthenticated = useSelector(state => state?.auth?.user)
  const isAuthenticating = typeof isAuthenticated === 'undefined'
  
  const searchParams = queryParams(window.location.search)
  const redirectTo = searchParams[redirectParamName] || `${PATHS.home}${window.location.search || ''}`
  
  if (isAuthenticating) { return null }
  if (!isAuthenticating && isAuthenticated && isURLAbsolute(redirectTo)) {
    // we'll use a <Redirect /> for non-absolute urls
    window.location.assign(redirectTo)
    return null
  }
  
  return !isAuthenticated ? <Route {...rest} >{children}</Route> : <Redirect to={redirectTo} />
}
