import React, {FunctionComponent, ReactElement} from 'react'
import { ModalProps } from './index'

const Generic:FunctionComponent<ModalProps> = function (props) : ReactElement {
  const { message, closeModal = () => {} } = props
  return <React.Fragment>
    <button className='close-modal icon red' onClick={() => closeModal()}><i className='fa fa-times'/></button>
    {message || null}
  </React.Fragment>
}

export default Generic
