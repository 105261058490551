import React, {useEffect} from 'react'
import ReactDOM from 'react-dom'
import { ModalProvider } from 'react-imperial-modal'
import { Provider, useSelector } from 'react-redux'
import App from './App'
import store from './store'
import firebase from './firebase'
import * as serviceWorker from './serviceWorker'
import { loginUser, logoutUser } from './store/Auth/AuthActions'
import { promptUpdate } from './store/UIState/UIStateActions'
import { autoResizeTextAreas } from './util/domUtils'

import './styles/base.scss'
import { reduxState } from './types'
import {usePrevious} from "./util";

autoResizeTextAreas()

firebase.auth().onAuthStateChanged(function(user) {
	if (user) {
		store.dispatch(loginUser(user))
	} else {
		store.dispatch(logoutUser())
	}
})

const promptUserToUpdate = (registration?) => {
	if (registration?.waiting) {
		store.dispatch(promptUpdate(true, registration?.waiting))
	}
}

const ThemeWrapper = function (props) {
	const activeTheme = useSelector((state: reduxState) => state?.preferences?.theme)
	const previousTheme = usePrevious(activeTheme)

	useEffect(() => {
		previousTheme && document.body.classList.remove(previousTheme)
		activeTheme && document.body.classList.add(activeTheme)
	}, [activeTheme, previousTheme])

	return props.children
}

ReactDOM.render(
	<React.StrictMode>
		<Provider store={store}>
			<ThemeWrapper>
				<ModalProvider>
					<App />
				</ModalProvider>
			</ThemeWrapper>
		</Provider>
	</React.StrictMode>,
	document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register({ onUpdate: promptUserToUpdate });