import {
  SET_USER_PREFERENCES
} from './PreferencesConstants'

export default function preferences (state = {}, action) {
  switch (action.type) {

    case SET_USER_PREFERENCES:
      return Object.assign({}, state, action.preferences)

    default:
      return state
  }
}