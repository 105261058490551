import { SET_USER_NOTES_SETTINGS, PRUNE_USER_NOTES } from './NotesConstants'
import { receiveSharedNotes } from './NotesActions'

export const notesMiddleware = store => next => action => {
  const state = store.getState()
  const allNotes = state?.notes?.notes
  const myNotes = state?.notes?.settings
  
  const setSharedNotes = (allNoteIds, myNoteIds) => {
    if (allNoteIds && myNoteIds) {
      const sharedNotes = allNoteIds.filter(noteId => !myNoteIds.includes(noteId))
      store.dispatch(receiveSharedNotes(sharedNotes))
    }
  }
  
  switch (action.type) {
    case SET_USER_NOTES_SETTINGS:
      if (allNotes) {
        setSharedNotes(Object.keys(allNotes), Object.keys(action.settings))
      }

      break
    
    case PRUNE_USER_NOTES:
      if (myNotes) {
        setSharedNotes(action.activeIds, Object.keys(myNotes))
      }
      
      break
    
    default:
      break
  }
  
  next(action)
}