import firebase from '../../firebase'
import { authorForFirebase } from '../index'
import {noteContentComponent, noteShape, userShape} from '../../types'
import React, {ReactElement} from 'react'
import {CONTENT_COMPONENTS, generateNewNote} from '../../constants'
import SVG from '../../components/SVG'
import {defaultNoteSettings} from '../../mock/defaultData'
import { removeNoteSettings, setNoteSettings } from './settingsUtil'
import {addNotesToGroup, removeNoteFromGroup} from './groupsUtil'
import {removeContributor} from "./contributorUtils";

export const getNote = function (noteId) {
  return firebase.database().ref(`notes/${noteId}`).once('value')
}

export const listenForNote = function (noteId, handler) {
  firebase.database().ref(`notes/${noteId}`).on('value', handler)
}

export const listenForUserNotes = function (email, handler) {
  firebase.database().ref(`userNotes/${email}`).on('value', handler)
}

export const createNewNote = function (user, group) {
  const normalizedEmail = authorForFirebase(user.email)
  const db = firebase.database()
  const thisNewNote = db.ref(`notes`).push()
  thisNewNote.set({...generateNewNote(), owner: normalizedEmail})

  db.ref(`noteUsers/${thisNewNote.key}/${normalizedEmail}`).set(true)
  db.ref(`userNotes/${normalizedEmail}/${thisNewNote.key}`).set(true)
  setNoteSettings(user, thisNewNote.key, defaultNoteSettings)

  if (group) {
    addNotesToGroup(user, [thisNewNote.key], group)
  }
  return thisNewNote
}

export const authorsForNote = (id) => {
  return firebase.database()
    .ref(`noteUsers/${id}`)
    .once('value' )
    .then(s => s?.val() && Object.keys(s.val()) )
}

export const setNoteProperty = function (noteId, propKey, propValue) {
  firebase.database().ref(`notes/${noteId}/${propKey}`).set(propValue)
}

export const setNoteContent = function (noteId, contentKey, contentValue) {
  firebase.database().ref(`notes/${noteId}/content/${contentKey}`).set(contentValue)
}

export const addNoteContent = function (noteId, contentValue) {
  firebase.database().ref(`notes/${noteId}/content`).push(contentValue)
}

export const removeSharedNote = (user: userShape, noteId: string, noteGroups: string[]) => {
  return user && authorsForNote(noteId).then(authors => {
    const db = firebase.database()
    const normalizedEmail = authorForFirebase(user.email)

    noteGroups.forEach(group => {
      removeNoteFromGroup(user, group, noteId)
    })

    if (authors.length > 1) {
      // note has other others.  just remove ourselves
      removeNoteSettings(user, noteId)
      removeContributor(noteId, normalizedEmail)
    } else {
      // we're the last owners.  delete the note as well.
      db.ref(`notes/${noteId}`).remove()
      removeNoteSettings(user, noteId)
      removeContributor(noteId, normalizedEmail)
    }
    return true
  })
}

export const getContentPreview = (contentItem : noteContentComponent) : ReactElement | null => {
  switch (contentItem.component) {

    case CONTENT_COMPONENTS.TEXT:
      return contentItem?.content?.substr(0, 100)

    case CONTENT_COMPONENTS.CHECKLIST:
      return Boolean(contentItem?.text) ? <React.Fragment>{contentItem.text}</React.Fragment> : null

    case CONTENT_COMPONENTS.IMAGE:
      return contentItem?.content?.url && <img src={contentItem.content.url} alt='thumbnail' />

    case CONTENT_COMPONENTS.DRAWING:
      return contentItem?.content?.vectorPaths && <SVG drawing {...contentItem?.content} />

    default:
      return null
  }
}


export const getNoteText = (note: noteShape) : string => {
  // we'll use this for searching notes - and getting the total text length of a note
  // so that we can size it in relation to the text length of its neighbors.
  if (!note || !note.content) { return '' }
  let noteString = note.title || ''

  Object.values(note.content).forEach((contentItem) => {
    // an image is worth about 70 characters :P
    if (contentItem.component === CONTENT_COMPONENTS.DRAWING) { noteString +=  ' '.repeat(150) }
    if (contentItem.component === CONTENT_COMPONENTS.IMAGE) { noteString +=  ' '.repeat(150) }
    if (contentItem.component === CONTENT_COMPONENTS.TEXT) { noteString += ' ' + contentItem.content }
    if (contentItem.component === CONTENT_COMPONENTS.CHECKLIST) {
      contentItem.content.forEach(checkListItem => noteString += ' ' + checkListItem.text)
    }
  })

  return noteString
}
