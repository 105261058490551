import React, {CSSProperties} from 'react'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { NavLink } from 'react-router-dom'
import SlideContainer from '../../../../../components/SlideContainer'
import longPressEvents from '../../../../../util/useLongpress'
import NoteListItem from '../../../../../components/NoteListItem'
import { useSelector } from 'react-redux'
import { PATHS } from '../../../../pathConstants'
import { reorder, stopEvent } from '../../../../../util'
import { updateGroup } from '../../../../../util/dbUtils/groupsUtil'
import { reduxState, userShape } from '../../../../../types'

import './NoteGroup.scss'

const NoteGroup = function (props) {
  const user = useSelector((state: reduxState)  => state?.auth?.user as userShape)
  const noteSettings = useSelector((state: reduxState) => state?.notes?.settings ?? {})
  const allNotesMap = useSelector((state: reduxState)  => state?.notes?.notes ?? {})

  const {
    addNote,
    toggleNote,
    selectedNotes,
    groupSet,
    groupId,
    isRealGroup,
    controls = [],
  } = props

  const thisGroup = groupSet[groupId]
  const groupStyle = { '--group-color': thisGroup.color  } as CSSProperties
  const hasIcon = Boolean(thisGroup.icon)

  const onDragEnd = (result) => {
    const noDestination = typeof result.destination === 'undefined'
    const sameList = result?.source?.droppableId === result?.destination?.droppableId
    const noMove = result?.destination?.index === result?.source?.index && sameList

    if (noDestination || noMove) { return }
    if (sameList) {
      const newNotes = {}
      const noteKeys = sortNotes(thisGroup.notes)
      const newNoteKeys = reorder([...noteKeys], result.source.index, result.destination.index)
      newNoteKeys.forEach((key, index) => {
        newNotes[key] = index + 1
      })

      updateGroup(user, groupId, { notes: newNotes })
    }
  }

  const sortNotes = notes => Object
    .keys(notes)
    .filter(noteId => allNotesMap[noteId])
    .sort((a, b) => {
      return Number(notes[a]) - Number(notes[b])
    })

  const renderNoteItem = (noteId, i, dragHandle?) => {
    const noteData = allNotesMap[noteId]
    const editNoteUrl = PATHS.edit.replace(':id', noteId)
    const hasSelection = selectedNotes.length > 0
    const isSelected = selectedNotes.includes(noteId)
    const isShared = !noteSettings[noteId]
    const handleToggle = e => {
      if (isShared) { return }
      toggleNote(noteId)
      stopEvent(e)
    }
    const longPressProps = longPressEvents(handleToggle)

    if (!noteData) { return null }

    const classNames = [
      'note-item',
      isShared ? 'shared': '',
      hasSelection ? 'show-toggle': '',
      isSelected ? 'selected' : ''
    ].join(' ')

    return <div key={`note-${noteId}`} className='note-wrapper'>
      <NavLink className={classNames} to={editNoteUrl}{ ...longPressProps }>
        {!isShared && <input
          type='checkbox'
          className='round-check'
          checked={isSelected}
          onChange={handleToggle}
          onClick={stopEvent} />}
        <NoteListItem shared={isShared} note={noteData} settings={noteSettings[noteId]}/>
      </NavLink>
      {dragHandle && <div {...dragHandle} className='drag-handle'>
        <i className='fas fa-grip-vertical' />
      </div>}
    </div>
  }

  return <SlideContainer
    key={groupId}
    style={groupStyle}
    controls={controls}
    controlWidth='3.5rem'
  >
    <div className='note-group'>
      <header>
        <h2 className='heading-1'>
          <div className='group-icon'>
            {hasIcon && <i className={thisGroup.icon} />}
          </div>
          {thisGroup.name}
        </h2>
      </header>


      {isRealGroup && <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId={`notes-${groupId}`}>
          {(provided, snapshot) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {sortNotes(thisGroup.notes || {}).map((noteId, index) => {
                const noteData = allNotesMap[noteId]
                return noteData && <Draggable key={noteId} draggableId={noteId} index={index}>

                  {(provided, snapshot) => {
                    const showShadow = !snapshot.isDropAnimating && snapshot.isDragging
                    return (
                      <div
                        className={`draggable-wrapper ${showShadow ? 'is-dragging' : ''}`}
                        ref={provided.innerRef}
                        {...provided.draggableProps}>
                        {renderNoteItem(noteId, index, provided.dragHandleProps)}
                      </div>
                    )
                  }}
                </Draggable>
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>}

      {!isRealGroup && sortNotes(thisGroup.notes || {}).map((n, i) => renderNoteItem(n, i))}

      {isRealGroup && <button className='green ghost add-note' onClick={() => addNote(groupId)}>
        <i className='fas fa-plus' /> Note
      </button>}

    </div>
  </SlideContainer>
}

export default NoteGroup