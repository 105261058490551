import {
  SET_USER_NOTES_SETTINGS,
  SET_NOTE_CONTENT,
  PRUNE_USER_NOTES,
  SET_SHARED_NOTES, SET_USER_NOTES_GROUPS, SET_INITIAL_NOTE_CONTENT,
} from './NotesConstants'
import { getNoteText } from '../../util/dbUtils/noteUtils'

export function receiveUserNotesGroups (groups) {
  return {
    type: SET_USER_NOTES_GROUPS,
    groups
  }
}

export function receiveUserNotesSettings (settings) {
  return {
    type: SET_USER_NOTES_SETTINGS,
    settings
  }
}

export function receiveUserNoteContent (id, note) {
  return {
    type: SET_NOTE_CONTENT,
    noteLength: getNoteText(note).length,
    note,
    id,
  }
}


export function receiveInitialUserNoteContent (noteMap) {
  const noteLengthMap = {}
  Object.keys(noteMap).forEach(id => noteLengthMap[id] = getNoteText(noteMap[id]).length)
  return {
    type: SET_INITIAL_NOTE_CONTENT,
    noteLengthMap,
    noteMap,
  }
}


export function receiveSharedNotes (ids) {
  return {
    type: SET_SHARED_NOTES,
    ids,
  }
}

export function pruneUserNoteContent (activeIds) {
  return {
    type: PRUNE_USER_NOTES,
    activeIds
  }
}