export const PATHS = {
  home: '/',
  homeOther: '/index.html',
  login: '/login',
  edit: '/notes/:id',
  editImage: '/notes/:id/image/:imageId',
  search: '/search',
  signIn: '/sign-in',
  showMe: '/show-me',
}
