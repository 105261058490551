import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { setNavMenu } from './store/UIState/UIStateActions'

const OnRouteLogic = function () {
  const history = useHistory()
  const dispatch = useDispatch()
  
  useEffect(() => {
    const routeChanged = () => {
      // stuff to do when the route changes
    
      dispatch(setNavMenu(false))
    }
    
    const stopListening = history.listen(routeChanged)
    return () => stopListening()
  }, [history, dispatch])
  
  return null
}

export default OnRouteLogic