import { CONTENT_COMPONENTS } from '../constants'

export const SETTING_KEYS = {
  completeCollapse: 'completeCollapse',
  sortComplete: 'sortComplete',
  spellcheck: 'spellcheck',
  bulletList: 'bulletList',
  color: 'color',
}

export const defaultNoteSettings = {
  [SETTING_KEYS.spellcheck]: false,
}

export const defaultNote = {
  title: 'Welcome 😍',
  content: [
    {
      component: CONTENT_COMPONENTS.TEXT,
      content: `Click the ( + ) to add a note.`
    }
  ]
}

export const defaultCategoryNote = {
  title: 'This category is now empty 😟',
  content: [
    {
      component: CONTENT_COMPONENTS.TEXT,
      content: `click the ( + ) to add a note to this category.`
    }
  ]
}
