
export const colors = [
  '#ff0000ff', '#ff4d00ff', '#ff9900ff', '#ffe500ff', '#ccff00ff',
  '#80ff00ff', '#33ff00ff', '#00ff19ff', '#00ff66ff', '#00ffb3ff',
  '#00ffffff', '#00b2ffff', '#0066ffff', '#0019ffff', '#3300ffff',
  '#7f00ffff', '#cc00ffff', '#ff00e6ff', '#ff0099ff', '#ff004cff',
  '#801300ff', '#806000ff', '#538000ff', '#068000ff', '#008046ff',
  '#006c80ff', '#002080ff', '#2d0080ff', '#790080ff', '#800039ff',
  '#93726cff', '#85936cff', '#6c9381ff', '#6c7693ff', '#916c93ff',
  '#212121ff', '#474747ff', '#6e6e6eff', '#949494ff', '#bababaff',
  '#edededff', '#ffffffff',
]



export const visibleOn = function (h, s, l, a) {
  // assume light background, so transparent colors lean white.
  const adjustedLumen = l + (51 * (1 - a))
  return [0, 0, adjustedLumen > 45 ? 0 : 100, 1]
}

export const getContrastColor = function (bgColor, alpha = 'ff') {
  var color = (bgColor.charAt(0) === '#') ? bgColor.substring(1, 7) : bgColor
  var r = parseInt(color.substring(0, 2), 16) // hexToR
  var g = parseInt(color.substring(2, 4), 16) // hexToG
  var b = parseInt(color.substring(4, 6), 16) // hexToB

  var uiColors = [r / 255, g / 255, b / 255]
  var c = uiColors.map((col) => {
    if (col <= 0.03928) { return col / 12.92 }
    return Math.pow((col + 0.055) / 1.055, 2.4)
  })
  var L = (0.2126 * c[0]) + (0.7152 * c[1]) + (0.0722 * c[2])

  return (L > 0.3) ? '#000000' + alpha : '#FFFFFF' + alpha
}

export const blendColors = function (colorA, colorB, amount = .5) {
  const aWithAlpha = colorA.padEnd(9, 'F')
  const bWithAlpha = colorB.padEnd(9, 'F')
  const [rA, gA, bA, oA] = aWithAlpha.match(/\w\w/g).map((c) => parseInt(c, 16));
  const [rB, gB, bB, oB] = bWithAlpha.match(/\w\w/g).map((c) => parseInt(c, 16));
  const r = Math.round((rA * amount) + (rB * (1 - amount))).toString(16).padStart(2, '0');
  const g = Math.round((gA * amount) + (gB * (1 - amount))).toString(16).padStart(2, '0');
  const b = Math.round((bA * amount) + (bB * (1 - amount))).toString(16).padStart(2, '0');
  const o = Math.round((oA * amount) + (oB * (1 - amount))).toString(16).padStart(2, '0');

  return '#' + r + g + b + o;
}

export const hslaToHex = function (h, s, l, a) {
  h /= 360;
  s /= 100;
  l /= 100;
  let r, g, b;
  if (s === 0) {
    r = g = b = l; // achromatic
  } else {
    const hue2rgb = (p, q, t) => {
      if (t < 0) t += 1;
      if (t > 1) t -= 1;
      if (t < 1 / 6) return p + (q - p) * 6 * t;
      if (t < 1 / 2) return q;
      if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
      return p;
    };
    const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
    const p = 2 * l - q;
    r = hue2rgb(p, q, h + 1 / 3);
    g = hue2rgb(p, q, h);
    b = hue2rgb(p, q, h - 1 / 3);
  }

  const toHex = x => {
    const hex = Math.round(x * 255).toString(16);
    return hex.length === 1 ? '0' + hex : hex;
  }
  return `#${toHex(r)}${toHex(g)}${toHex(b)}${toHex(a)}`;
}

export const anyColor = ( ) => {
  return colors[ Math.floor( Math.random() * colors.length )]
}