import React, {FunctionComponent, ReactElement} from 'react'
import { ModalProps } from './index'

const Confirm:FunctionComponent<ModalProps> = function (props) : ReactElement {
  const { title, message, closeModal = () => {} } = props

  return <React.Fragment>
    <button className='close-modal icon red' onClick={() => closeModal()}><i className='fa fa-times'/></button>
    <div className='medium-padding large-padding-bottom'>
      {title && <h2 className='modal-title large-margin-bottom'>{title}</h2>}
      {message || null}
    </div>
    <div className='small-padding'>
      <button className='on-foreground' onClick={() => closeModal(false)}>cancel</button>
      <button className='on-foreground' onClick={() => closeModal(true)}>continue</button>
    </div>
  </React.Fragment>
}

export default Confirm
