import React, {FunctionComponent, ReactElement, useEffect} from 'react'
import { useSelector } from 'react-redux'
import {
	BrowserRouter as Router,
	Switch,
	Route
} from 'react-router-dom'
import { AnonOnlyRoute, AuthOnlyRoute } from './util/router'
import asyncComponent from './components/AsyncComponent'
import NavMenu from './components/NavMenu'
import Home from './routes/Home'
import Login from './routes/Login'
import Search from './routes/Search'
import OnRouteLogic from './OnRouteLogic'
import { PATHS } from './routes/pathConstants'
import SignIn from './routes/SignIn'
import {reduxState} from './types'
import UpdatePrompt from './components/UpdatePrompt/UpdatePrompt'

import './App.scss'

const ImageEditAsyncRoute = asyncComponent(() => import(/* webpackChunkName: "image-edit" */ './routes/ImageEdit'))
const NoteEditAsyncRoute = asyncComponent(() => import(/* webpackChunkName: "note-edit" */ './routes/NoteEdit'))
const ShowMeAsyncRoute = asyncComponent(() => import(/* webpackChunkName: "show-me" */ './routes/ShowMe'))

const App : FunctionComponent = function () : ReactElement {
	const navOpen = useSelector((state: reduxState) => state?.uiState?.navOpen)
	const updatePrompt = useSelector((state: reduxState) => state?.uiState?.updatePrompt)

	useEffect(() => {
		if (navOpen) { document.body.classList.add('nav-open')}
		if (!navOpen) { document.body.classList.remove('nav-open')}
	}, [navOpen])

	return (
		<Router>
			<OnRouteLogic />
			<div className={`notes-app ${navOpen ? 'blur' : ''}`}>
				<Switch>
					<AnonOnlyRoute path={PATHS.login}><Login /></AnonOnlyRoute>
					<AuthOnlyRoute path={PATHS.editImage}><ImageEditAsyncRoute /></AuthOnlyRoute>
					<AuthOnlyRoute exact path={PATHS.edit}><NoteEditAsyncRoute /></AuthOnlyRoute>
					<AuthOnlyRoute exact path={PATHS.search}><Search /></AuthOnlyRoute>
					<AuthOnlyRoute exact path={PATHS.home}><Home /></AuthOnlyRoute>
					<AuthOnlyRoute exact path={PATHS.homeOther}><Home /></AuthOnlyRoute>
					<AnonOnlyRoute exact path={PATHS.signIn}><SignIn /></AnonOnlyRoute>
					<Route exact path={PATHS.showMe}><ShowMeAsyncRoute /></Route>
				</Switch>
				{updatePrompt && <UpdatePrompt />}
			</div>
			<NavMenu />
		</Router>
	)
}

export default App