import React from 'react'
import firebase from '../../firebase'
import { useDispatch } from 'react-redux'
import { toggleNavMenu } from '../../store/UIState/UIStateActions'

import './UserInfo.scss'

const UserInfo = function ({ user }) {
  const dispatch = useDispatch()
  
  const handleSignOut = () => {
    firebase.auth().signOut()
    dispatch(toggleNavMenu())
  }
  
  return <div className='user-info'>
    <div className='details'>
      <h2>{user?.displayName}</h2>
      <div>{user?.email}</div>
      <button className='faux-link' onClick={handleSignOut}>sign out</button>
    </div>
    {user.photoURL && <img src={user.photoURL} alt={user?.displayName} />}
  </div>
}

export default UserInfo