import { PROMPT_UPDATE, SET_NAV, TOGGLE_NAV } from './UIStateActions'
import { uiState as TuiState} from '../../types'

export default function uiState (state: TuiState = {}, action) {
  switch (action.type) {
    case TOGGLE_NAV:
      return Object.assign({}, state, { navOpen: !state.navOpen })

    case SET_NAV:
      return Object.assign({}, state, { navOpen: action.state })

    case PROMPT_UPDATE:
      return Object.assign({}, state, { updatePrompt: action.state, worker: action.worker })

    default:
      return state
  }
}
