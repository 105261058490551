export default function queryParams (locationSearch) {
  let pl = /\+/g
  let params = {}
  let paramsArray = locationSearch && locationSearch.replace(pl, ' ').substring(1).split('&')
  paramsArray && paramsArray.forEach((item) => {
    let paramName = item.split('=')[0]
    let paramValue = decodeURIComponent(item.split('=')[1])
    if (paramName) {
      if (!params[paramName]) {
        params[paramName] = paramValue
      } else {
        if (Array.isArray(params[paramName])) {
          params[paramName].push(paramValue)
        } else {
          params[paramName] = [params[paramName], paramValue]
        }
      }
    }
  })
  return params
}

export function toParamString (params = {}) {
  return Object.keys(params).map(key => {
    if (Array.isArray(params[key])) {
      return params[key].map(value => value && `${key}=${value}`).flat().join('&')
    }
    return params[key] && `${key}=${params[key]}`
  }).flat().join('&')
}

export const isURLAbsolute = url => /^[a-zA-Z][a-zA-Z\d+\-.]*:/.test(url)