import React, { CSSProperties } from 'react'
import {FunctionComponent, ReactChildren, ReactElement} from 'react'
import PeekElement from 'react-peek-element'
import { useDispatch, useSelector } from 'react-redux'
import { toggleNavMenu } from '../../store/UIState/UIStateActions'
import { reduxState } from '../../types'
import { getContrastColor } from '../../util/color'

import './Header.scss'

type HeaderProps = {
  children?: ReactChildren | ReactElement,
  background?: string,
  navButton?: ReactElement | false,
  secondRow?: ReactElement | false,
}

const Header:FunctionComponent<HeaderProps> = function (props) : ReactElement {
  const { children, background, navButton, secondRow } = props
  const dispatch = useDispatch()
  const handleToggleNav = () => dispatch(toggleNavMenu())
  const sharedNotes = useSelector((state: reduxState) => state?.notes?.sharedNotes ?? [])

  const hasSharedNotes = sharedNotes?.length > 0
  const withNavClasses = 'small-8 medium-10 '
  const withoutNavClasses = 'small-10 medium-11 '
  const headerStyle = background ? {
    '--foreground': background,
    '--copy-color': getContrastColor(background),
  } : {}

  return <PeekElement usePlaceHolder>
    <header className='site-header' style={headerStyle as CSSProperties}>
      <div className='grid-container no-padding'>
        <div className='row no-gutter primary-row'>
          {navButton && <div className='small-2 medium-1 text-left no-cell-padding'>{navButton}</div>}
          <div className={`${navButton ? withNavClasses : withoutNavClasses} no-cell-padding child-region`}>
            { children }
          </div>
          <div className='small-2 medium-1 text-right no-cell-padding hamburger'>
            <button className='ghost icon large' onClick={handleToggleNav}>
              <i className={`fas fa-bars ${hasSharedNotes ? 'notification' : ''}`} />
            </button>
          </div>
        </div>
        {secondRow && <div className='row no-gutter'>{secondRow}</div>}
      </div>
    </header>
  </PeekElement>
}

export default Header