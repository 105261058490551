import { LOGIN_USER, LOGOUT_USER } from './AuthConstants'
import {
  pruneUserNoteContent,
  receiveInitialUserNoteContent,
  receiveUserNoteContent,
  receiveUserNotesGroups,
  receiveUserNotesSettings
} from '../Notes/NotesActions'
import { authorForFirebase } from '../../util'
import { listenForNoteGroups } from '../../util/dbUtils/groupsUtil'
import { listenForNoteSettings } from '../../util/dbUtils/settingsUtil'
import { getNote, listenForNote, listenForUserNotes } from '../../util/dbUtils/noteUtils'

export const authMiddleware = store => next => action => {

  switch (action.type) {
    case LOGIN_USER:
      const user = action?.user

      const normalizedEmail = authorForFirebase(user.email)

      listenForUserNotes(normalizedEmail, snap => {
        const allNotes = snap.val()
        if (allNotes) {
          const ids = Object.keys(allNotes)
          const ignoredFirst = {}

          store.dispatch(pruneUserNoteContent(ids))

          // get all notes up front with `once`
          const notePromises = ids.map(id => getNote(id))
          Promise.all(notePromises).then(snapShots => {
            const noteMap = {}
            snapShots.forEach(snapShot => noteMap[snapShot.key] = snapShot.val())
            store.dispatch(receiveInitialUserNoteContent(noteMap))
          })

          // listen for subsequent changes with `on` - ignoring the first event
          ids.forEach(id => listenForNote(id, snapshot => {
            if (ignoredFirst[id]) {
              store.dispatch(receiveUserNoteContent(id, snapshot.val()))
            } else {
              ignoredFirst[id] = true
            }
          }))

        } else {
          store.dispatch(pruneUserNoteContent([]))
          store.dispatch(receiveUserNotesSettings({  }))
        }
      })

      listenForNoteSettings(user, snap => store.dispatch(receiveUserNotesSettings(snap.val() || {})))
      listenForNoteGroups(user, snap => store.dispatch(receiveUserNotesGroups(snap.val() || {})))

      break

    case LOGOUT_USER:

      break

    default:
      break
  }

  next(action)
}