import { LOGIN_USER, LOGOUT_USER } from '../Auth/AuthConstants'
import { receiveUserPreferences } from './PreferencesActions'
import { listenForPreferences } from '../../util/dbUtils/preferencesUtil'

export const preferencesMiddleware = store => next => action => {

  switch (action.type) {
    case LOGIN_USER:
      const user = action?.user

      listenForPreferences(user, snapshot => {
        const preferences = snapshot.val() || {}
        store.dispatch(receiveUserPreferences(preferences))
      })

      break

    case LOGOUT_USER:
      break

    default:
      break
  }

  next(action)
}