import firebase from 'firebase/app'
import 'firebase/database'
import 'firebase/auth'

try {
	const firebaseConfig = {
		apiKey: "AIzaSyCrkdBo7k9Vx0zHQKMfql8kbl-eHGrgCx8",
		authDomain: "auth.noted.ink",
		databaseURL: "http://notes-2-ef892.firebaseio.com",
		projectId: "notes-2-ef892",
		storageBucket: "notes-2-ef892.appspot.com",
		messagingSenderId: "244993049085",
		appId: "1:244993049085:web:e6d773f23d7b6b5ba55994"
	};
	// Initialize Firebase
	firebase.initializeApp(firebaseConfig)

} catch (e) {

}

export const googleProvider = new firebase.auth.GoogleAuthProvider()
export const faceBookProvider = new firebase.auth.FacebookAuthProvider()

export default firebase
