import React, {useRef, useState, useEffect, FunctionComponent, ReactElement} from 'react'
import { useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'
import Mason from 'react-stone-mason'
import Header from '../../components/Header'
import { PATHS } from '../pathConstants'
import { debounce } from '../../util'
import {useDocumentTitle, useThemeColor} from '../../util/domUtils'
import { getNoteText } from '../../util/dbUtils/noteUtils'
import {DEFAULT_MASON, DEFAULT_THEME, HOME_VIEWS, THEMES} from '../../constants'
import NoteCard from '../../components/NoteCard'
import { reduxState } from '../../types'

import './Search.scss'
import NoteListItem from "../../components/NoteListItem";
import {useLocalStorage} from "@rehooks/local-storage";

const doSearch = debounce((term: string, notes: Object, callBack: Function) : void => {
  const matchingNotes = {}
  const searchReg = new RegExp(term, 'gi')

  Object.keys(notes).forEach(noteId => {
    const matches = (notes[noteId].match(searchReg) || []).length
    if (matches) {
      matchingNotes[noteId] = matches
    }
  })

  callBack(matchingNotes)
}, 200)

const Search: FunctionComponent = function () : ReactElement {
  const activeTheme = useSelector((state: reduxState) => state?.preferences?.theme)
  const allNotesMap = useSelector((state: reduxState) => state?.notes?.notes ?? {})
  const noteSettings = useSelector((state: reduxState) => state?.notes?.settings ?? {})
  const [ activeView ] = useLocalStorage('activeView', HOME_VIEWS.grid)
  const [ searchTerm, setSearchTerm ] = useState('')
  const [ noteSet, setNoteSet ] = useState({ })
  const searchField = useRef<HTMLInputElement>(null)
  const allNotesText = {}
  const setNotesText = {}
  const displayNotes = {}
  const sizePriorities = {}

  useDocumentTitle('Noted | search')
  useThemeColor(THEMES[activeTheme] || THEMES[DEFAULT_THEME])

  Object.keys(allNotesMap).forEach(noteId => {
    const noteText = getNoteText(allNotesMap?.[noteId])
    allNotesText[noteId] = noteText
    if (noteSet[noteId]) {
      setNotesText[noteId] = noteText.length
    }
  })
  Object.keys(noteSet).forEach(noteId => { displayNotes[noteId] = allNotesMap?.[noteId] })

  const textLengths = Object.values(setNotesText).sort()
  Object.keys(noteSet).forEach(noteId => { sizePriorities[noteId] = textLengths.indexOf(setNotesText[noteId]) / textLengths.length })

  useEffect(() => { searchField?.current?.focus() }, [])

  const sortMatchCount = (a, b) => noteSet?.[b] - noteSet?.[a]
  const handleSearchInput = (term) => {
    setSearchTerm(term)
    term.length > 2 && doSearch(term, allNotesText, setNoteSet)
    term.length < 3 && setNoteSet({ })
  }

  const HeaderNavButton = <NavLink to={PATHS.home} className='button ghost medium-large icon'>
    <i className='fas fa-arrow-left' />
  </NavLink>

  return <React.Fragment>
    <Header navButton={HeaderNavButton} >
      <h2 className='no-margin input-container search-header-input'>
        <i className='fas fa-search'/>
        <input ref={searchField} placeholder='search' value={searchTerm} onChange={e => handleSearchInput(e.target.value)} />
      </h2>
    </Header>
    <div className='search-container' >
      <div className='grid-container no-padding'>
        {activeView === HOME_VIEWS.grid && <Mason columns={DEFAULT_MASON}>
          {Object.keys(noteSet).sort(sortMatchCount).map((noteId, i) => {
            const editNoteUrl = PATHS.edit.replace(':id', noteId)
            return <NavLink key={`n${i}`} className='note-item' to={editNoteUrl}>
              <NoteCard
                note={allNotesMap[noteId]}
                settings={noteSettings[noteId]}
                sizePriority={sizePriorities[noteId]}
              />
            </NavLink>
          })}
        </Mason>}
        {activeView === HOME_VIEWS.list && <div className='search-list-view'>
          {Object.keys(noteSet).sort(sortMatchCount).map((noteId, i) => {
            const editNoteUrl = PATHS.edit.replace(':id', noteId)
            return <NavLink key={`n${i}`} className='note-item medium-padding-bottom' to={editNoteUrl}>
              <NoteListItem
                note={allNotesMap[noteId]}
                settings={noteSettings[noteId]}
              />
            </NavLink>
          })}

        </div>}
      </div>
    </div>
  </React.Fragment>
}

export default Search