import React from 'react'
import { reduxState } from '../../types'
import { useDispatch, useSelector } from 'react-redux'
import UserInfo from './UserInfo'
import { NavLink } from 'react-router-dom'
import { PATHS } from '../../routes/pathConstants'
import { toggleNavMenu } from '../../store/UIState/UIStateActions'
import { HOME_VIEW_NAMES, HOME_VIEWS, THEME_NAMES } from '../../constants'
import {useLocalStorage} from '@rehooks/local-storage'
import {setThemePreference} from '../../util/dbUtils/preferencesUtil'

import './NavMenu.scss'

const NavMenu = function () {
  const user = useSelector((state: reduxState) => state?.auth?.user)
  const notes = useSelector((state: reduxState) => state?.notes?.notes)
  const sharedNotes = useSelector((state: reduxState) => state?.notes?.sharedNotes ?? [])
  const noteGroups = useSelector((state: reduxState) => state?.notes?.groups ?? {})
  const activeTheme = useSelector((state: reduxState) => state?.preferences?.theme)
  const navOpen = useSelector((state: reduxState) => state?.uiState?.navOpen)
  const [ activeView, setActiveView ] = useLocalStorage('activeView', HOME_VIEWS.grid)
  const [ activeGroup, setActiveGroup ] = useLocalStorage('activeGroup', '')

  const dispatch = useDispatch()

  const noteGroupIds = Object.keys(noteGroups)
  const hasNoteGroups = noteGroupIds?.length > 0

  const handleSelectGroup = (group) => {
    setActiveGroup(group)
    dispatch(toggleNavMenu())
  }

  const handleSelectView = (view) => {
    setActiveView(view)
  }

  const handleSelectTheme = (theme) => {
    if (user?.uid) {
      setThemePreference(user, theme)
    }
  }

  return <React.Fragment>
    <nav className={`nav-menu ${navOpen ? 'open' : ''}`}>
      {user && <UserInfo user={user}/>}
      <div className='menu-items'>

        {user && <div className='search menu-item'>
          <h3><NavLink to={PATHS.search}><i className='fas fa-search'/>Search</NavLink></h3>
        </div>}

        {user && <div className='views menu-item'>
          <h3>
            {activeView === HOME_VIEWS.grid && <i className='fas fa-th-large' />}
            {activeView === HOME_VIEWS.list && <i className='fas fa-list-ul' />}
            View
          </h3>
          {HOME_VIEW_NAMES.map(view => <div key={`view-${view}`} className='child-menu-item'>
            <label>
              <input
                type='radio'
                className='just-check'
                checked={activeView === view}
                onChange={() => handleSelectView(view)}/>
              <span className='medium-padding-left'>{view}</span>
            </label>
          </div>)}
        </div>}

        {user && <div className='themes menu-item'>
          <h3><i className='fas fa-paint-roller '/>Theme</h3>
          {THEME_NAMES.map((theme, i) => <div key={`theme-${theme}`} className='child-menu-item'>
            <label>
              <input type='radio' className='just-check' checked={activeTheme === theme} onChange={() => handleSelectTheme(theme)}/>
              <span className='medium-padding-left'>{theme}</span>
            </label>
          </div>)}
        </div>}

        {user && hasNoteGroups && <div className='categories menu-item'>
          <h3><i className='fas fa-tag'/>Categories</h3>
          <div className='child-menu-item'>
            <label>
              <input type='radio' className='just-check' checked={!activeGroup} onChange={() => handleSelectGroup('')}/>
              <span className='medium-padding-left'>
                <i className='fas fa-tag' />
                all notes
              </span>
            </label>
          </div>
          {noteGroupIds.map((group, i) => <div key={`group-${group}`} className='child-menu-item'>
            <label>
              <input type='radio' className='just-check' checked={activeGroup === group} onChange={() => handleSelectGroup(group)}/>
              <span className='medium-padding-left'>
                {noteGroups[group].icon && <i className={noteGroups[group].icon} />}
                {noteGroups[group].name}
              </span>
            </label>
          </div>)}
        </div>}

        {user && sharedNotes?.length > 0 && <div className='shared menu-item'>
          <h3><i className='fas fa-user-friends'/>Shared with me</h3>
          {sharedNotes.map((noteId, i) => {
            const title = notes?.[noteId]?.title || `-untitled-`
            return <div key={`sn-${noteId}`} className='child-menu-item'>
              <NavLink to={PATHS.edit.replace(':id', noteId)}>{title}</NavLink>
            </div>
          })}
        </div>}
      </div>
    </nav>
    {navOpen && <div className='nav-shade' onClick={() => dispatch(toggleNavMenu())}/>}
  </React.Fragment>
}

export default NavMenu