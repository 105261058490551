type TCallback = (...args: any[]) => void

export default function longPressEvents(callback: TCallback, ms = 500) {
  let timeout: NodeJS.Timer | null = null

  const start = () => timeout = setTimeout(callback, ms)
  const stop = () => timeout && window.clearTimeout(timeout)

  return {
    onTouchStart: start,
    onTouchMove: stop,
    onTouchEnd: stop,
  }
}