import React, {FunctionComponent, ReactElement} from 'react'
import SVGPath, { SVGPathHTML } from '../SVGPath/SVGPath'
import { HSL_WHITE } from '../../constants'
import { hslaToHex } from '../../util/color'
import { HSLAColor } from '../../types'

const colorToHex = (color: HSLAColor) => hslaToHex(color.hue, color.saturation, color.lumen, color.opacity)

type vectorPath = {
  color: string
  width: number
  points: [number, number][]
}

type SVGProps = {
  drawing: boolean,
  vectorBackground?: HSLAColor,
  vectorHeight: number,
  vectorWidth: number,
  vectorPaths: vectorPath[]
}

const SVG:FunctionComponent<SVGProps> = function (props) : ReactElement | null {
  const { drawing, vectorBackground = HSL_WHITE, vectorHeight = 0, vectorWidth = 0, vectorPaths } = props

  return vectorPaths ? <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox={`0 0 ${vectorWidth} ${vectorHeight}`}
    preserveAspectRatio='xMinYMin meet'
    style={{ maxWidth: vectorWidth, maxHeight: vectorHeight }}
  >
    <defs/>
    <g>
      {drawing && <path
        className='svg-background'
        fill={colorToHex(vectorBackground)}
        d={`M 0, 0 L ${vectorWidth}, 0 L ${vectorWidth}, ${vectorHeight} L 0 , ${vectorHeight} Z`} />}

      {vectorPaths && Object.entries(vectorPaths).map(([key, path]) => (
        <SVGPath key={key} id={key} {...path} />
      ))}
    </g>
  </svg> : null
}

export const SvgHTML = function (props: SVGProps) : string {
  const { drawing, vectorBackground = HSL_WHITE, vectorHeight = 0, vectorWidth = 0, vectorPaths = [] } = props

  return `<svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 ${vectorWidth} ${vectorHeight}'
    width='${vectorWidth}'
    height='${vectorHeight}'
    preserveAspectRatio='xMinYMin meet'>
    <defs/>
    <g>
${drawing && `<path fill='${colorToHex(vectorBackground)}' d='M 0, 0 L ${vectorWidth}, 0 L ${vectorWidth}, ${vectorHeight} L 0 , ${vectorHeight} Z' />`}
${Object.entries(vectorPaths).map(([key, path]) => (
        `      ` + SVGPathHTML({ key, id: key, ...path}) + '\n'
      )).join('')}
    </g>
  </svg>`
}
  
export default SVG
