import { useRef, useEffect } from 'react'
import { IMAGE_FILTERS } from '../constants'
import { validation} from '../types'

// for drag and drop re-ordering
export const reorder = (list : Array<any>, startIndex : number, endIndex: number) : Array<any> => {
	const result = Array.from(list);
	const [removed] = result.splice(startIndex, 1);
	result.splice(endIndex, 0, removed);

	return result
}

export const authorForFirebase = (email : string) : string => email.toLowerCase().replace(/\./g, '%2E')
export const authorFromFirebase = (email : string) : string => email.replace(/%2E/g, '.').toLowerCase()

export const debounce = <F extends (...args: any[]) => any>(func: F, waitFor: number) => {
	let timeout: ReturnType<typeof setTimeout> | null = null

	const debounced = (...args: Parameters<F>) => {
		if (timeout !== null) {
			clearTimeout(timeout)
			timeout = null
		}
		timeout = setTimeout(() => func(...args), waitFor)
	}

	return debounced as (...args: Parameters<F>) => ReturnType<F>
}

export function usePrevious<T>(value: T) {
	const ref = useRef<T>()
	useEffect(() => { ref.current = value }, [value])
	return ref.current as T
}

export function stopEvent (e) : void {
	e && e.stopPropagation()
}

export function killEvent (e) : void {
	if (e) {
		e.preventDefault()
		e.stopPropagation()
	}
}

export function getFilterCSS (appliedFilters : Object) : string {
	const cssFilters = [] as string[]
	appliedFilters && Object.keys(appliedFilters).forEach(filter => {
		const thisFilter = IMAGE_FILTERS[filter]
		if (thisFilter) {
			const filterCSS = `${thisFilter.cssKey}(${thisFilter.groom(appliedFilters[filter])})`
			cssFilters.push(filterCSS)
		}
	})

	return cssFilters.join(' ')
}

export const isEmail = (email : string) : boolean => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)

export const emailValidation: validation = { fn: isEmail, message: 'Please enter a valid email' }

export const emailPrefix = (email : string) : string => email.split('@')[0]

