import React, { ReactElement } from 'react'
import { useSelector } from 'react-redux'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { useModal } from 'react-imperial-modal'
import { useLocalStorage } from '@rehooks/local-storage'
import { defaultCategoryNote, defaultNote } from '../../../../mock/defaultData'
import { reorder } from '../../../../util'
import { Confirm, EditCategory } from '../../../../components/Modals'
import {
  createNewGroup,
  removeGroup,
  updateAllGroups,
  updateGroup
} from '../../../../util/dbUtils/groupsUtil'
import NoteGroup from './components/NoteGroup'
import { createFauxGroup, NO_GROUP, SHARED } from '../../HomeConstants'
import { reduxState, userShape } from '../../../../types'

import './ListView.scss'

const ListView = function (props): ReactElement {
  const user = useSelector((state: reduxState)  => state?.auth?.user as userShape)
  const [ activeGroup ] = useLocalStorage('activeGroup', '')
  const noteSettings = useSelector((state: reduxState) => state?.notes?.settings ?? {})
  const allNotesMap = useSelector((state: reduxState)  => state?.notes?.notes ?? {})
  const noteGroups = useSelector((state: reduxState)  => state?.notes?.groups ?? {})

  const {
    addNote,
    hasNotes,
    selectedNotes,
    toggleNote,
  } = props

  const [ openModal, closeModal ] = useModal()

  const sharedNotes = {}
  const ungroupedNotes = {}
  const groupNames = Object.keys(noteGroups)
  const hasGroups = Boolean(groupNames.length)


  Object.keys(allNotesMap).forEach((noteId) => {
    const isShared = !noteSettings?.[noteId]
    const isGrouped = groupNames.find(group => noteGroups?.[group]?.notes?.[noteId])

    isShared && (sharedNotes[noteId] = true)
    !isGrouped && !isShared && (ungroupedNotes[noteId] = true)
  })

  const fauxNoteGroups = {
    [NO_GROUP]: createFauxGroup(NO_GROUP, ungroupedNotes, null, 'fas fa-tag', 0),
    [SHARED]: createFauxGroup(SHARED, sharedNotes, null, 'fas fa-user-friends', 1),
  }

  const wrapInSection = (note) => <section className='note-group large-margin-left large-margin-right'>{note}</section>

  const handleEditGroup = (group) => {
    const thisGroup = noteGroups[group]
    const EditModal = <EditCategory
      title={`Edit ${thisGroup.name}`}
      category={thisGroup}
      closeModal={result => closeModal(EditModal, result)} />

    openModal(EditModal).then(updatedGroup => updatedGroup && updateGroup(user, group, updatedGroup))
  }

  const handleDeleteGroup = (group) => {
    const ConfirmModal = <Confirm
      title='Are you quite certain?'
      message={`The notes in this category will not be deleted`}
      closeModal={result => closeModal(ConfirmModal, result) } />

    openModal(ConfirmModal).then(result => result && removeGroup(user, group))
  }

  const handleAddGroup = () => {
    if (user) {
      const AddModal = <EditCategory
        title='New Category'
        closeModal={result => closeModal(AddModal, result)} />

      openModal(AddModal).then(newGroup => newGroup && createNewGroup(user, newGroup))
    }
  }

  const onDragEnd = (result) => {
    const noDestination = typeof result.destination === 'undefined'
    const sameList = result?.source?.droppableId === result?.destination?.droppableId
    const noMove = result?.destination?.index === result?.source?.index && sameList

    if (noDestination || noMove) { return }
    if (sameList) {

      const newNoteGroups = {}
      const groupKeys = sortSet(noteGroups)
      const newGroupKeys = reorder([...groupKeys], result.source.index, result.destination.index)
      newGroupKeys.forEach((key, index) => {
        newNoteGroups[key + '/order'] = index
      })

      updateAllGroups(user, newNoteGroups)
    }
  }

  const renderControls = (gId, provided) => {
    const bClass = 'gray ghost icon group-control button'
    const controlButton = (key, props, icon) => (
      <div key={gId + key} role='button' className={bClass} {...props} ><i className={icon} /></div>
    )

    return [
      controlButton('delete', { onClick: () => handleDeleteGroup(gId) }, 'fas fa-trash-alt'),
      controlButton('edit', { onClick: () => handleEditGroup(gId) }, 'fas fa-pen'),
      !activeGroup && controlButton('drag', provided.dragHandleProps, 'fas fa-grip-vertical'),
    ]
  }

  const sortSet = groupSet => {
    return Object
      .keys(groupSet)
      .sort((a, b) => {
        return (groupSet[a]?.order ?? Infinity) - (groupSet[b]?.order ?? Infinity)
      })
  }

  const renderGroupSet = (groupSet, isRealGroup) => sortSet(groupSet).map((groupId, groupIndex) => {
    const showNotes = !activeGroup || activeGroup === groupId

    if (!showNotes) {
      return null
    }

    if (isRealGroup) {
      return <Draggable key={groupId} draggableId={groupId} index={groupIndex}>
        {(provided, snapshot) => {
          const showShadow = !snapshot.isDropAnimating && snapshot.isDragging
          const controls = renderControls(groupId, provided)

          return <div
            ref={provided.innerRef}
            className={`draggable-wrapper ${showShadow ? 'is-dragging' : ''}`}>
            <div {...provided.draggableProps} >
              <NoteGroup
                addNote={addNote}
                toggleNote={toggleNote}
                selectedNotes={selectedNotes}
                groupSet={groupSet}
                groupId={groupId}
                isRealGroup={isRealGroup}
                controls={controls}
              />
            </div>
          </div>
        }}
      </Draggable>
    } else {
      return <NoteGroup
        key={groupId}
        addNote={addNote}
        toggleNote={toggleNote}
        selectedNotes={selectedNotes}
        groupSet={groupSet}
        groupId={groupId}
        isRealGroup={isRealGroup}
      />
    }
  })

  const stubNote = note => wrapInSection(<div>
    <h2>{note.title}</h2>
    {note.content[0].content}
  </div>)

  return <div className='grid-container list-view'>
      {!hasNotes && !activeGroup && stubNote(defaultNote)}
      {!hasNotes && activeGroup && stubNote(defaultCategoryNote)}

      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId='note-groups'>
          {(provided, snapshot) => (
            <div {...provided.droppableProps} ref={provided.innerRef} >
              {hasNotes && renderGroupSet(noteGroups, true)}
              {provided.placeholder}
            </div>
          )}
        </Droppable>

        {hasNotes && renderGroupSet(fauxNoteGroups, false)}
      </DragDropContext>

      {hasGroups && <div className='new-category'>
        <button
          className='green ghost'
          onClick={handleAddGroup}>
          <i className='fas fa-plus' /> Category
        </button>
      </div>}
  </div>
}

export default ListView
