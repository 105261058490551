import React, {useState, useRef, useEffect, FunctionComponent, ReactElement} from 'react'
import { ModalProps } from './index'

const Prompt:FunctionComponent<ModalProps> = function (props) : ReactElement {
  const {
    title,
    message,
    closeModal = () => {},
    groom = value => value,
    autoComplete = 'off',
    validate = {}
  } = props

  const [ value, setValue ] = useState('')
  const [ validity, setValidity ] = useState({})
  const inputRef = useRef<HTMLInputElement>(null)
  const isValid = Object.keys(validity).length === 0

  const handleChange = e => {
    const newValue = e.target.value
    const validState = {}

    Object.keys(validate).forEach(test => {
      if ( !validate[test].fn(newValue) ) {
        validState[test] = validate[test].message
      }
    })

    setValidity(validState)
    setValue(groom(newValue))
  }

  useEffect(() => { inputRef?.current?.focus() }, [inputRef])

  return <React.Fragment>
    <button className='close-modal icon red' onClick={() => closeModal()}><i className='fa fa-times'/></button>
    <div className='medium-padding large-padding-bottom'>
      {title && <h2 className='modal-title large-margin-bottom'>{title}</h2>}
      {message || null}
      <input className='on-foreground large-padding' ref={inputRef} value={value} autoComplete={autoComplete} onChange={handleChange} />
      {!isValid && <div className='validation-messages medium-padding-top'>
        {Object.keys(validity).map((test, i) => <div key={`v${i}`}>
          <strong style={{color: 'var(--red)'}}>{validity[test]}</strong>
        </div>)}
      </div> }
    </div>
    <div className='small-padding'>
      <button className='on-foreground' onClick={() => closeModal(false)}>cancel</button>
      <button disabled={!value || !isValid} className='on-foreground' onClick={() => closeModal(value)}>continue</button>
    </div>
  </React.Fragment>
}

export default Prompt
