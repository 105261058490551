import firebase from '../../firebase'

export const listenForContributors = function (noteId, handler) {
  firebase.database().ref(`noteUsers/${noteId}`).on('value', handler)
}

export const stopListeningForContributors = function (noteId, handler) {
  firebase.database().ref(`noteUsers/${noteId}`).off('value', handler)
}

export const removeContributor = function (noteId, email) {
  firebase.database().ref(`userNotes/${email}/${noteId}`).remove()
  firebase.database().ref(`noteUsers/${noteId}/${email}`).remove()
}

export const addContributor = function (noteId, email) {
  firebase.database().ref(`userNotes/${email}/${noteId}`).set(true)
  firebase.database().ref(`noteUsers/${noteId}/${email}`).set(true)
}