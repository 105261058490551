import { useEffect, useRef } from "react"

// oof.  listen for resize and input events.
// and override the 'value' prop setter
export const autoResizeTextAreas = function () : void {
  const descriptor = Object.getOwnPropertyDescriptor(HTMLTextAreaElement.prototype, 'value');
  const originalSet = descriptor?.set

  const doResize = function (textArea: HTMLTextAreaElement) {
    window.requestAnimationFrame(() => {
      const scrollPos = { left: window.scrollX, top: window.scrollY }
      textArea.style.height = 'auto'
      textArea.style.height = textArea.scrollHeight + 'px'
      window.scrollTo(scrollPos)
    })
  }

  if (descriptor && originalSet) {
    descriptor.set = function(val) {
      const newValue:string = arguments[0]
      originalSet?.apply(this, [newValue])

      // @ts-ignore
      doResize(this)
    }

    Object.defineProperty(HTMLTextAreaElement.prototype, 'value', descriptor)
  }

  window.addEventListener('resize', _event => {
    Array.from(document.querySelectorAll('textarea')).forEach(textArea => doResize(textArea))
  }, { passive: true })

  // @ts-ignore
  window.addEventListener('input', (event: InputEvent) => {
    const target = event.target

    // @ts-ignore
    target !== null && target?.tagName === 'TEXTAREA' && doResize(target)
  },  { passive: true })
}

export const randomItemFrom = array => {
  return array[Math.floor(Math.random() * array.length)]
}

export const randomId = (length = 10) => {
  let id = ''
  const alphabet = 'ModuleSymbhasOwnPr-0123456789ABCDEFGHNRVfgctiUvz_KqYTJkLxpZXIjQW'.split('')
  while (length--) { id += randomItemFrom(alphabet) }
  return id
}

export const setSpellCheck = (element: HTMLTextAreaElement, state: boolean) : void => {
  const storeValue = element.value
  element.setAttribute('spellcheck', String(state))
  element.value = ''
  element.value = storeValue
}

export function useDocumentTitle(title : string) : void {
  const defaultTitle = useRef(document.title);
  useEffect(() => {
    const oldTitle = defaultTitle.current
    document.title = title
    return () => { document.title = oldTitle }
  }, [title])
}

export function useThemeColor(color : string) : void {
  const metaTag = document.querySelector('meta[name=theme-color]')
  const defaultColor = useRef(metaTag?.getAttribute('content') || '#000')

  useEffect(() => {
    const oldColor = defaultColor.current
    metaTag && metaTag.setAttribute('content', color)
    return () => { metaTag && metaTag.setAttribute('content', oldColor) }
  }, [color, metaTag])
}

