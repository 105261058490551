import React, {CSSProperties, FunctionComponent, ReactElement, useRef} from 'react'
import firebase, { googleProvider, faceBookProvider } from '../../firebase'
import {anyColor, getContrastColor} from '../../util/color'
import { useModal } from 'react-imperial-modal'
import { Alert, Prompt } from '../../components/Modals'
import { emailValidation } from '../../util'
import { NavLink } from 'react-router-dom'
import { PATHS } from '../pathConstants'
import { FirebaseError } from 'firebase'
import { useThemeColor } from '../../util/domUtils'

import './Login.scss'

const isStandalone = 'standalone' in window.navigator

const getButtonBackground = color => {
	const buttonColor = getContrastColor(color, '')
	if (buttonColor === '#000000') {
		return buttonColor + '40'
	}
	return buttonColor + 'AA'
}

const Login: FunctionComponent = function () : ReactElement {
	const [ openModal, closeModal ] = useModal()
	const loginColor = useRef<string>(anyColor())
	const contrastColor = useRef<string>(getContrastColor(loginColor.current))
	const contrastContrastColor = useRef<string>(getButtonBackground(contrastColor.current))


	useThemeColor(loginColor.current)

	const failMessages = {
		'auth/account-exists-with-different-credential': ({email}) => `It looks like you've already signed in with an account linked to '${email}'.  Try using that account.`
	}

	const emailCodeSettings = {
		url: 'https://noted.ink/sign-in?redirect=/',
		handleCodeInApp: true,
	}

	const doEmailLogin = () : void => {
		const EmailPrompt = <Prompt
			title={`email address`}
			validate={{ email: emailValidation }}
			autoComplete='email'
			message={<React.Fragment>
				<p>We'll send you a link that will sign you in.</p>
				<p><em>No password to remember!</em></p>
			</React.Fragment>}
			closeModal={result => closeModal(EmailPrompt, result)} />

		openModal(EmailPrompt).then(email => {
			if (email) {
				firebase.auth().sendSignInLinkToEmail(email, emailCodeSettings)
					.then(() => {
						window.localStorage.setItem('emailForSignIn', email)
						const successModal = <Alert
							title='Got it!'
							message='check your email.'
							closeModal={() => { closeModal(successModal) }} />
						openModal(successModal)
					})
					.catch(handleLoginError)
			}
		})
	}

	const doLogin = (provider) : (event: React.MouseEvent) => void  => {
		return () : Promise<any> => {
			if (isStandalone) {
				return firebase.auth().signInWithRedirect(provider).catch(handleLoginError)
			} else {
				return firebase.auth().signInWithPopup(provider).catch(handleLoginError)
			}
		}
	}

	const handleLoginError = (e: FirebaseError) : void => {
		const message = failMessages[e.code]?.(e) ?? e.message

		const errorModal = <Alert
			title='Oh dear..'
			message={message}
			closeModal={() => { closeModal(errorModal) }} />
		openModal(errorModal)
	}

	const loginStyle = {
		'--copy-color': contrastColor.current,
		'--contrast-color': contrastContrastColor.current,
		backgroundColor: loginColor.current,
		color: contrastColor.current
	} as CSSProperties

	return (
		<div className="loginContainer" style={loginStyle} >
			<div className="login" >
				<div className='medium-padding'>
					<h1>Noted</h1>
					<p><strong>Write, share, search and organize your notes. <NavLink to={PATHS.showMe}>show me</NavLink></strong></p>
					<p className='small-margin-bottom large-padding-top'>Sign in:</p>
				</div>
				<button className='on-foreground' onClick={doLogin(googleProvider)}>google</button>
				<button className='on-foreground' onClick={doLogin(faceBookProvider)}>facebook</button>
				{!isStandalone && <button className='on-foreground' onClick={doEmailLogin}>@email</button>}

				<div className='medium-padding large-padding-top no-padding-bottom text-right'>
					<a href='/privacy-policy.html'>privacy policy</a>
				</div>
			</div>
		</div>
	)

}

export default Login;
