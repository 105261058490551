import React, {FunctionComponent, ReactElement, useEffect} from 'react'
import firebase from '../../firebase';
import { Alert, Prompt } from '../../components/Modals'
import { emailValidation } from '../../util'
import { useModal } from 'react-imperial-modal'
import {FirebaseError} from "firebase";

const SignIn: FunctionComponent = function () : ReactElement | null {
  const [ openModal, closeModal ] = useModal()

  useEffect(() => {

    const handleLoginError = (e: FirebaseError) : void => {
      const message = e.message
      const errorModal = <Alert
        title='Oh dear..'
        message={message}
        closeModal={() => { closeModal(errorModal) }} />
      openModal(errorModal)
    }


    const askForEmail = () : Promise<any> => {
      const EmailPrompt = <Prompt
        title={`email address`}
        validate={{ email: emailValidation }}
        autoComplete='email'
        message={<React.Fragment>
          <p>Just to confirm, what was that email address?</p>
        </React.Fragment>}
        closeModal={result => closeModal(EmailPrompt, result)} />

      return openModal(EmailPrompt)
    }

    const signInWithEmail = (email: string) : void => {
      firebase.auth().signInWithEmailLink(email, window.location.href)
        .then(function(result) {
          window.localStorage.removeItem('emailForSignIn');
        })
        .catch(handleLoginError)
    }


    if (firebase.auth().isSignInWithEmailLink(window.location.href)) {
      var email = window.localStorage.getItem('emailForSignIn');
      if (!email) {
        askForEmail().then(email => email && signInWithEmail(email))

      } else {
        signInWithEmail(email)
      }
    }
  }, [openModal, closeModal])

  return null
}

export default SignIn
