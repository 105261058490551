import firebase from '../../firebase'
import {generateNewGroup, } from '../../constants'

export const setAllGroups = function (user, groups) {
  return firebase.database().ref(`users/${user.uid}/noteCategories`).set(groups)
}

export const updateAllGroups = function (user, groups) {
  return firebase.database().ref(`users/${user.uid}/noteCategories`).update(groups)
}

export const removeGroup = function (user, groupId) {
  return firebase.database().ref(`users/${user.uid}/noteCategories/${groupId}`).remove()
}

export const updateGroup = function (user, groupId, group) {
  const db = firebase.database()
  return db.ref(`users/${user.uid}/noteCategories/${groupId}`).update(group)
}

export const createNewGroup = function (user, group) {
  const db = firebase.database()
  const thisNewGroup = db.ref(`users/${user.uid}/noteCategories`).push()
  thisNewGroup.set({...generateNewGroup(), ...group})

  return thisNewGroup
}

export const addNotesToGroup = function (user, ids, group) {
  const updatedGroup = {}
  ids.forEach(noteId => updatedGroup[noteId] = 1)

  const dbAddress = `users/${user.uid}/noteCategories/${group}/notes`
  firebase.database().ref(dbAddress).update(updatedGroup)
}

export const toggleNoteGroup = function (user, group, noteId) {
  const dbAddress = `users/${user.uid}/noteCategories/${group}/notes/${noteId}`
  firebase.database().ref(dbAddress).once('value').then(s => {
    if (s.val()) {
      firebase.database().ref(dbAddress).remove()
    } else {
      firebase.database().ref(dbAddress).set(1)
    }
  })
}

export const removeNoteFromGroup = function (user, group, noteId) {
  const dbAddress = `users/${user.uid}/noteCategories/${group}/notes/${noteId}`
  firebase.database().ref(dbAddress).remove()
}

export const listenForNoteGroups = function (user, handler) {
  const db = firebase.database()
  db.ref(`users/${user.uid}/noteCategories`).on('value', handler)
}