import {
  SET_USER_NOTES_SETTINGS,
  SET_NOTE_CONTENT, PRUNE_USER_NOTES, SET_SHARED_NOTES, SET_USER_NOTES_GROUPS, SET_INITIAL_NOTE_CONTENT,
} from './NotesConstants'

export default function notes (state = {  }, action) {
  switch (action.type) {
    case SET_USER_NOTES_SETTINGS:
      return Object.assign({}, state, { settings: action.settings })
    
      case SET_USER_NOTES_GROUPS:
      return Object.assign({}, state, { groups: action.groups })
  
    case SET_NOTE_CONTENT:
      let newNotes = {
        noteLengths: { ...state.noteLengths, [action.id]: action.noteLength } ,
        notes: { ...state.notes, [action.id]: action.note },
      }
      return Object.assign({}, state, newNotes)
    
    case SET_INITIAL_NOTE_CONTENT:
      let initialNotes = {
        noteLengths: action.noteLengthMap,
        notes: action.noteMap,
      }
      return Object.assign({}, state, initialNotes)
  
    case SET_SHARED_NOTES:
      return Object.assign({}, state, { sharedNotes: action.ids })
  
    case PRUNE_USER_NOTES:
      const prunedNotes = {}
      action.activeIds.forEach(id => { prunedNotes[id] = state?.notes?.[id] ?? null })
      
      return Object.assign({}, state, { notes: prunedNotes })
  
  
    default:
      return state
  }
}