import React, {FunctionComponent, ReactElement, SVGProps} from 'react'

type SVGPathProps = {
  key?: string,
  id?: string,
  color: string,
  width: number,
  points: [number, number][]
}

const SVGPath:FunctionComponent<SVGPathProps> = function(props) : ReactElement {
  const {id, color, width, points = []} = props
  const defaultProps = {
    'fill': 'none',
    'strokeLinecap': 'round',
    'strokeLinejoin': 'round',
    'strokeMiterlimit': '10',
    'paintOrder': 'fill stroke markers'
  } as SVGProps<SVGPathElement>

  const pathProps = {
    'stroke': color,
    'strokeWidth': width,
    'd': `M ${points.join(' L ')}`,
  }

  return <path data-id={id} {...defaultProps} {...pathProps} />
}

export const SVGPathHTML = function(props: SVGPathProps): string {
  const {color, width, points} = props
  const defaultProps = {
    'fill': 'none',
    'stroke-linecap': 'round',
    'stroke-linejoin': 'round',
    'stroke-miterlimit': '10',
    'paint-order': 'fill stroke markers'
  }
  const pathProps = {
    'stroke': color,
    'stroke-width': width,
    'd': `M ${points.join(' L ')}`,
  }

  const objToAttrs = obj => Object.entries(obj).map(([key, value]) => `${key}='${value}' `).join('')
  
  return `<path ${objToAttrs(pathProps)} ${objToAttrs(defaultProps)} />`
}

export default SVGPath
