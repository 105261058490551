import {
  LOGIN_USER,
  LOGOUT_USER,
} from './AuthConstants'

export default function auth (state = {}, action) {
  switch (action.type) {
    case LOGIN_USER:
      return Object.assign({}, state, { user: action.user })

    case LOGOUT_USER:
      return Object.assign({}, state, { user: false })

    default:
      return state
  }
}