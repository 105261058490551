import firebase from '../../firebase'

export const listenForNoteSettings = function (user, handler) {
  const db = firebase.database()
  db.ref(`users/${user.uid}/noteSettings`).on('value', handler)
}

export const removeNoteSettings = function (user, noteId) {
  const db = firebase.database()
  db.ref(`users/${user.uid}/noteSettings/${noteId}`).remove()
}

export const setNoteSettings = function (user, noteId, settings) {
  const db = firebase.database()
  db.ref(`users/${user.uid}/noteSettings/${noteId}`).set(settings)
}