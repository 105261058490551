import React, {useState, useRef, useEffect, FunctionComponent, ReactElement, CSSProperties} from 'react'
import { ModalProps } from './index'
import { colors, getContrastColor } from '../../util/color'
import { ICONS, MORE_ICONS } from '../../util/icons'
import { noteGroup } from '../../types'

import './EditCategory.scss'

const iconSet = [...ICONS, ...MORE_ICONS]

interface editCategoryModalProps extends ModalProps {
  category?: noteGroup;
}

const EditCategory:FunctionComponent<editCategoryModalProps> = function (props) : ReactElement {
  const {
    title,
    message,
    closeModal = () => {},
    autoComplete = 'off',
    category = { name: '', color: '', icon: '', notes: {} },
  } = props

  const [ name, setName ] = useState('')
  const [ color, setColor ] = useState('')
  const [ icon, setIcon ] = useState('')
  const inputRef = useRef<HTMLInputElement>(null)

  const newCategory = { ...category, name, color, icon }
  const handleChange = e => setName(e.target.value)

  useEffect(() => {
    if (category) {
      const { name, color, icon } = category
      name && setName(name)
      color && setColor(color)
      icon && setIcon(icon)
    }
  }, [category])

  useEffect(() => { inputRef?.current?.focus() }, [inputRef])

  return <div className='constrained-half edit-category-modal'>
    <button className='close-modal icon red' onClick={() => closeModal()}><i className='fa fa-times'/></button>
    <div className='medium-padding large-padding-bottom'>
      {title && <h2 className='modal-title large-margin-bottom'>{title}</h2>}
      {message || null}
      <label className='block'>
        category name
        <input
          className='on-foreground medium-padding'
          ref={inputRef}
          value={name}
          autoComplete={autoComplete}
          onChange={handleChange} />
      </label>

      <div className='large-padding-top text-center'>
        {colors.map((colorValue, i) => {
          const contrast = getContrastColor(colorValue)
          const style = {'--color2': colorValue, '--contrast': contrast} as CSSProperties
          return <button
            key={`c${i}`}
            onClick={() => setColor(colorValue)}
            className={`color-swatch ${color === colorValue ? 'toggled' : ''}`}
            style={style}>&nbsp;<span className='visually-hidden'>{colorValue}</span>
          </button>
        })}
      </div>

      <div className='large-padding-top icon-picker text-center'>
        {iconSet.map((iconValue, i) => <button
          key={`i${i}`}
          style={{ '--color3': 'var(--copy-color)'} as CSSProperties}
          className={`large icon ${icon === iconValue ? 'toggled' : ''}`}
          onClick={() => setIcon(iconValue)}
        ><i className={iconValue}/>
        </button>)}
      </div>

    </div>
    <div className='small-padding text-right'>
      <button className='' onClick={() => closeModal(false)}>cancel</button>
      <button className='on-foreground blue' onClick={() => closeModal(newCategory)}>save</button>
    </div>
  </div>
}

export default EditCategory
