import React, {CSSProperties, ReactElement, useMemo, useState} from 'react'
import { useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'
import Mason from 'react-stone-mason'
import { useModal } from 'react-imperial-modal'
import {DEFAULT_MASON} from '../../../../constants'
import NoteCard from '../../../../components/NoteCard'
import { defaultCategoryNote, defaultNote } from '../../../../mock/defaultData'
import { PATHS} from '../../../pathConstants'
import { stopEvent} from '../../../../util'
import longPressEvents from '../../../../util/useLongpress'
import { createFauxGroup, NO_GROUP, SHARED } from '../../HomeConstants'
import { Confirm, EditCategory } from '../../../../components/Modals'
import { createNewGroup, removeGroup, updateGroup } from '../../../../util/dbUtils/groupsUtil'
import { reduxState, userShape } from '../../../../types'

import './GridView.scss'

const GridView = function (props): ReactElement {
  const user = useSelector((state: reduxState)  => state?.auth?.user as userShape)
  const [ openModal, closeModal ] = useModal()

  const {
    addNote,
    hasNotes,
    activeGroup,
    noteGroups,
    selectedNotes,
    noteSettings,
    toggleNote,
    allNotesMap,
    allLengthsMap,
  } = props

  const hasGroups = Boolean(Object.keys(noteGroups).length)
  const sizePriorities = {}
  const textLengths = Object.values(allLengthsMap).sort((a, b) => Number(a) - Number(b))
  Object.keys(allNotesMap).forEach(noteId => { sizePriorities[noteId] = textLengths.indexOf(allLengthsMap[noteId]) / textLengths.length })

  const sortSet = groupSet => {
    return Object
      .keys(groupSet)
      .sort((a, b) => {
        return (groupSet[a]?.order ?? Infinity) - (groupSet[b]?.order ?? Infinity)
      })
  }

  const sortNotes = notes => Object
    .keys(notes || {})
    .filter(noteId => allNotesMap[noteId])
    .sort((a, b) => {
      return Number(notes[a]) - Number(notes[b])
    })

  const [displayGroups, fauxGroups] = useMemo(() => {
    const groupNames = Object.keys(noteGroups)
    const displayGroups = {}
    const fauxGroups = {}
    const sharedNotes = {}
    const ungroupedNotes = {}

    if (activeGroup) {
      displayGroups[activeGroup] = noteGroups[activeGroup]
    } else {
      Object.keys(allNotesMap).forEach((noteId) => {
        const isShared = !noteSettings?.[noteId]
        const isGrouped = groupNames.find(group => noteGroups?.[group]?.notes?.[noteId])

        isShared && (sharedNotes[noteId] = true)
        !isGrouped && !isShared && (ungroupedNotes[noteId] = true)
      })

      fauxGroups[NO_GROUP] = createFauxGroup(NO_GROUP, ungroupedNotes, 'var(--copy-color-semi)', 'fas fa-tag', 0)
      fauxGroups[SHARED] = createFauxGroup(SHARED, sharedNotes, 'var(--copy-color-semi)', 'fas fa-user-friends', 1)

      Object.keys(noteGroups).forEach(groupId => {
        displayGroups[groupId] = noteGroups[groupId]
      })
    }
    return [displayGroups, fauxGroups]
  }, [activeGroup, allNotesMap, noteGroups, noteSettings])

  const handleEditGroup = (groupId) => {
    const thisGroup = noteGroups[groupId]
    const EditModal = <EditCategory
      title={`Edit ${thisGroup.name}`}
      category={thisGroup}
      closeModal={result => closeModal(EditModal, result)} />

    openModal(EditModal).then(updatedGroup => updatedGroup && updateGroup(user, groupId, updatedGroup))
  }


  const handleAddGroup = () => {
    if (user) {
      const AddModal = <EditCategory
        title='New Category'
        closeModal={result => closeModal(AddModal, result)} />

      openModal(AddModal).then(newGroup => newGroup && createNewGroup(user, newGroup))
    }
  }

  const handleDeleteGroup = (groupId) => {
    const ConfirmModal = <Confirm
      title='Are you quite certain?'
      message={`The notes in this category will not be deleted`}
      closeModal={result => closeModal(ConfirmModal, result) } />

    openModal(ConfirmModal).then(result => result && removeGroup(user, groupId))
  }

  const GroupHeading = ({groupId, group}) => {
    const [expanded, setExpanded] = useState(false)

    const itemStyle = { '--group-color': group.color } as CSSProperties
    return <div className='group-item' style={itemStyle} >
      <h2 className='heading-2 clear-fix' tabIndex={0} onClick={() => setExpanded(s => !s)}>
        <div className='group-name'>
          {group.icon && <i className={group.icon} />}
          {group.name}
        </div>

        {expanded && noteGroups[groupId] && <div className='group-actions'>
          <button className='small ghost icon' onClick={() => handleDeleteGroup(groupId)}>
            <i className='fas fa-trash-alt' />
          </button>
          <button className='small ghost icon' onClick={() => handleEditGroup(groupId)}>
            <i className='fas fa-pen' />
          </button>
          <button className='small ghost icon' onClick={() => addNote(groupId)}>
            <i className='fas fa-plus' />
          </button>
        </div>}
      </h2>
    </div>
  }


  const renderGroupSet = (groupSet) => {
    const children = [] as ReactElement[]

    sortSet(groupSet).forEach((groupId) => {
      hasGroups && children.push(<GroupHeading key={groupId} groupId={groupId} group={groupSet[groupId]} />)

      sortNotes(groupSet[groupId]?.notes).forEach((noteId, noteIndex) => {
        const editNoteUrl = PATHS.edit.replace(':id', noteId)
        const hasSelection = selectedNotes.length > 0
        const isSelected = selectedNotes.includes(noteId)
        const isShared = !noteSettings[noteId]
        const handleToggle = e => {
          if (isShared) { return }
          toggleNote(noteId)
          stopEvent(e)
        }
        const longPressProps = longPressEvents(handleToggle)

        const classNames = [
          'note-item',
          isShared ? 'shared': '',
          hasSelection ? 'show-toggle': '',
          isSelected ? 'selected' : ''
        ].join(' ')

        children.push(<React.Fragment key={`note-${noteId}`}>
          <div
            className={classNames}
            {...longPressProps}
            onClick={e => hasSelection && handleToggle(e)}>

            <NavLink to={editNoteUrl}>
              <NoteCard
                shared={isShared}
                note={allNotesMap[noteId]}
                settings={noteSettings[noteId]}
                sizePriority={sizePriorities[noteId]}
              />
            </NavLink>

            {!isShared && <input
              type='checkbox'
              className='round-check'
              checked={isSelected}
              onChange={handleToggle}
              onClick={stopEvent}/>}
          </div>
        </React.Fragment>)
      })
    })

    return children
  }

  return <div className='grid-container grid-view'>
    <Mason columns={DEFAULT_MASON}>
      {!hasNotes && !activeGroup && <NoteCard note={defaultNote} />}
      {!hasNotes && activeGroup && <NoteCard note={defaultCategoryNote} />}
      {renderGroupSet(displayGroups)}
      {renderGroupSet(fauxGroups)}
      {hasGroups && <div className='new-category'>
        <button
          className='green ghost'
          onClick={handleAddGroup}>
          <i className='fas fa-plus' /> Category
        </button>
      </div>}
    </Mason>
  </div>
}

export default GridView
