import React from 'react'


type asyncState = {
  component?: React.ElementType | null
}

export default function asyncComponent (importComponent: Function) {
  class AsyncComponent extends React.Component<{}, asyncState> {

    state: asyncState = {  }

    async componentDidMount () {
      const { default: component } = await importComponent()
      this.setState({
        component: component
      })
    }

    render () {
      const C = this.state?.component
      return C ? <C {...this.props} /> : null
    }
  }

  return AsyncComponent
}
