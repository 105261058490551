import React, {FunctionComponent, ReactElement} from 'react'
import { noteShape, noteSettings } from '../../types'
import { SETTING_KEYS } from '../../mock/defaultData'

import './NoteListItem.scss'

type NoteListItemProps = {
  note: noteShape,
  settings?: noteSettings,
  shared?: boolean,
  showToggle?: Function
}

const NoteListItem:FunctionComponent<NoteListItemProps> = function (props) : ReactElement | null {
  const { note, settings, shared, showToggle } = props

  const cardStyle = {
    '--note-color': settings?.[SETTING_KEYS.color] ?? 'var(--medium-gray)',
  } as React.CSSProperties

  return note ? <article className={`note-list-item ${showToggle ? 'show-toggle' : ''} ${shared ? 'shared' : ''}`} style={cardStyle}>
    <div className='note-list-content'>
      <h3 className='note-title heading-4'>{note.title || '-untitled-'}</h3>
    </div>
  </article> : null
}

export default NoteListItem
